import ActivityWidget from "../components/widgets/ActivityWidget";
import AffirmationWidget from "../components/widgets/AffirmationWidget";
import AgentEvolutionWidget from "../components/widgets/AgentEvolutionWidget";
import AutomationSavingsWidget from "../components/widgets/AutomationSavingsWidget";
import CalendarWidget from "../components/widgets/CalendarWidget";
import DecisionSupportWidget from "../components/widgets/DecisionSupportWidget";
import FinanceWidget from "../components/widgets/FinanceWidget";
import GoalsWidget from "../components/widgets/GoalsWidget";
import MarkdownWidget from "../components/widgets/MarkdownWidget";
import MindbotsInsightWidget from "../components/widgets/MindbotsInsightWidget";
import NewsWidget from "../components/widgets/NewsWidget";
import PerformanceWidget from "../components/widgets/PerformanceWidget";
import QualityOfLifeAffirmationsWidget from "../components/widgets/QualityOfLifeAffirmationsWidget";
import QualityOfLifeBalanceWidget from "../components/widgets/QualityOfLifeBalanceWidget";
import QualityOfLifeHabitsWidget from "../components/widgets/QualityOfLifeHabitsWidget";
import QualityOfLifeInsightsWidget from "../components/widgets/QualityOfLifeInsightsWidget";
import QualityOfLifeMoodWidget from "../components/widgets/QualityOfLifeMoodWidget";
import QualityOfLifeSleepWidget from "../components/widgets/QualityOfLifeSleepWidget";
import QualityOfLifeWidget from "../components/widgets/QualityOfLifeWidget";
import QuoteWidget from "../components/widgets/QuoteWidget";
import TaskCompletionWidget from "../components/widgets/TaskCompletionWidget";
import WeatherWidget from "../components/widgets/WeatherWidget";
import { Widget, WIDGET_TYPES } from "../types/Widget";

export const renderWidget = (
  widget: Widget,
  interactionsComponent?: React.ReactElement
) => {
  const commonProps = {
    interactionsComponent,
  };

  switch (widget.type) {
    case WIDGET_TYPES.activityTracker:
      return <ActivityWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.agentEvolution:
      return <AgentEvolutionWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.decisionSupport:
      return <DecisionSupportWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.goalsTracker:
      return <GoalsWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.mindbotsInsights:
      return <MindbotsInsightWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.newsFeed:
      return <NewsWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.qualityOfLifeInsights:
      return (
        <QualityOfLifeInsightsWidget {...commonProps} data={widget.data} />
      );
    case WIDGET_TYPES.dailyQuote:
      return <QuoteWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.automationSavings:
      return <AutomationSavingsWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.calendarOverview:
      return <CalendarWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.financeOverview:
      return <FinanceWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.markdownNotes:
      return <MarkdownWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.performanceMetrics:
      return <PerformanceWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.qualityOfLifeBalance:
      return <QualityOfLifeBalanceWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.qualityOfLifeHabits:
      return <QualityOfLifeHabitsWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.qualityOfLifeMood:
      return <QualityOfLifeMoodWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.qualityOfLifeSleep:
      return <QualityOfLifeSleepWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.qualityOfLifeAffirmations:
      return (
        <QualityOfLifeAffirmationsWidget {...commonProps} data={widget.data} />
      );
    case WIDGET_TYPES.qualityOfLife:
      return <QualityOfLifeWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.taskCompletion:
      return <TaskCompletionWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.affirmations:
      return <AffirmationWidget {...commonProps} data={widget.data} />;
    case WIDGET_TYPES.weatherInfo:
      return <WeatherWidget {...commonProps} />;
    default:
      return null;
  }
};
