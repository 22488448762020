import { dashboardInstance } from "../config/dashboardInstance";
import { Pagination } from "../types/Pagination";
import { Widget, WidgetType } from "../types/Widget";
import { handleApiError } from "../utils/handleApiError";

const user_id = "123";

type BaseResponse = {
  success: boolean;
  message: string;
};

export type GetDashboardWidgetsResponse = BaseResponse & {
  data: {
    items: Widget[];
    pagination: Pagination;
  };
};

type GetDashboardWidgetsProps = {
  page?: number;
  page_size?: number;
  dashboard_id: string;
};

export const getDashboardWidgets = async ({
  page = 1,
  page_size = 10,
  dashboard_id,
}: GetDashboardWidgetsProps) => {
  try {
    const response = await dashboardInstance.get<GetDashboardWidgetsResponse>(
      `/dashboard/${dashboard_id}/widgets`,
      {
        params: {
          user_id,
          page,
          page_size,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    throw handleApiError(error, {
      404: "Space not found",
    });
  }
};

type UpdateWidgetReactionResponse = BaseResponse & {
  id: string;
  type: WidgetType;
  is_liked: boolean;
  is_disliked: boolean;
  updated_at: string;
};

type UpdateWidgetReactionProps = {
  is_liked: boolean;
  is_disliked: boolean;
  widget_id: string;
};

export const updateWidgetReaction = async ({
  is_disliked,
  is_liked,
  widget_id,
}: UpdateWidgetReactionProps) => {
  try {
    const response = await dashboardInstance.put<UpdateWidgetReactionResponse>(
      `/widgets/${widget_id}/reaction`,
      {
        is_liked,
        is_disliked,
        user_id,
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error, {
      400: "Widget cannot be both liked and disliked",
      404: "Widget not found",
    });
  }
};

type UpdateWidgetOrderResponse = BaseResponse & {
  data: string;
};

type UpdateWidgetProps = {
  widget_id: string;
  dashboard_id: string;
  position: number;
};

export const updateWidgetOrder = async ({
  position,
  widget_id,
  dashboard_id,
}: UpdateWidgetProps) => {
  try {
    const response = await dashboardInstance.put<UpdateWidgetOrderResponse>(
      `/dashboard/${dashboard_id}/widgets/${widget_id}/settings`,
      {
        position: position,
      },
      {
        params: {
          user_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

export const addWidgetToDashboard = async ({
  dashboardId,
  widgetId,
}: {
  dashboardId: string;
  widgetId: string;
}) => {
  try {
    const response = await dashboardInstance.post<BaseResponse>(
      `/dashboard/widget/assign`,
      {
        user_id,
        dashboard_ids: [dashboardId],
        widget_id: widgetId,
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error, {
      400: "Widget not found or invalid dashboard IDs",
    });
  }
};

export const removeWidgetFromDashboard = async ({
  dashboardId,
  widgetId,
}: {
  dashboardId: string;
  widgetId: string;
}) => {
  try {
    const response = await dashboardInstance.delete<BaseResponse>(
      `/dashboard/${dashboardId}/widgets/${widgetId}`,
      {
        params: {
          user_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
