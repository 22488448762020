import { DashboardFields } from "../validations/dashboard";
import { dashboardInstance } from "../config/dashboardInstance";
import { handleApiError } from "../utils/handleApiError";

export type Dashboard = {
  id: string;
  name: string;
  created_at: string;
  widgets: string[];
};

const user_id = "123";

type BaseResponse = {
  success: boolean;
  message: string;
};

type Pagination = {
  page: number;
  page_size: number;
  total_pages: number;
  total_items: number;
  has_next: boolean;
  has_previous: boolean;
};

export type GetDashboardsResponse = BaseResponse & {
  data: {
    items: Dashboard[];
    pagination: Pagination;
  };
};

type GetDashboardsParams = {
  page?: number;
  page_size?: number;
};

export const getDashboards = async ({
  page = 1,
  page_size = 10,
}: GetDashboardsParams) => {
  try {
    const response = await dashboardInstance.get<GetDashboardsResponse>(
      "/dashboard",
      {
        params: {
          user_id,
          page,
          page_size,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

type CreateDashboardResponse = BaseResponse & {
  data: Dashboard;
};

export const createDashboard = async (data: DashboardFields) => {
  try {
    const response = await dashboardInstance.post<CreateDashboardResponse>(
      "/dashboard",
      {
        user_id,
        ...data,
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

type DeleteDashboardResponse = BaseResponse & {
  data: string;
};

export const deleteDashboard = async (id: string) => {
  try {
    const response = await dashboardInstance.delete<DeleteDashboardResponse>(
      `/dashboard/${id}`,
      {
        params: {
          user_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};

type UpdateDashboardResponse = BaseResponse & {
  data: string;
};

export const updateDashboard = async (
  data: DashboardFields & { id: string }
) => {
  try {
    const response = await dashboardInstance.patch<UpdateDashboardResponse>(
      `/dashboard/${data.id}`,
      {
        ...data,
      },
      {
        params: {
          user_id,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw handleApiError(error);
  }
};
