import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { Box, Chip, IconButton, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";
import { formatDistanceToNow, parseISO } from "date-fns";

interface Decision {
  title: string;
  context: string;
  priority: "high" | "medium" | "low";
  deadline: string;
  status: "pending" | "analyzing" | "ready";
}

export type DecisionSupportWidgetData = {
  decisions: Decision[];
};

export type DecisionSupportWidgetProps = BaseWidgetProps & {
  data: DecisionSupportWidgetData;
};

const DecisionSupportWidget: React.FC<DecisionSupportWidgetProps> = ({
  data: { decisions },
  interactionsComponent,
}) => {
  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case "high":
        return "#FF2D55";
      case "medium":
        return "#FF9F0A";
      case "low":
        return "#32D74B";
      default:
        return "#86868b";
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case "ready":
        return "#32D74B";
      case "analyzing":
        return "#0A84FF";
      case "pending":
        return "#86868b";
      default:
        return "#86868b";
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        p: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AccountTreeIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Decision Support
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      {decisions.map((decision, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#fafafa",
            borderRadius: "16px",
            padding: 2,
            marginBottom: index !== decisions.length - 1 ? 2 : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 1,
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: 500, color: "#1d1d1f" }}
            >
              {decision.title}
            </Typography>
            <IconButton size="small" data-no-dnd="true">
              <MoreHorizIcon sx={{ fontSize: 20, color: "#86868b" }} />
            </IconButton>
          </Box>

          <Typography
            sx={{ color: "#86868b", fontSize: "13px", marginBottom: 2 }}
          >
            {decision.context}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <Chip
                icon={<PriorityHighIcon sx={{ fontSize: 16 }} />}
                label={decision.priority}
                size="small"
                sx={{
                  backgroundColor: `${getPriorityColor(decision.priority)}15`,
                  color: getPriorityColor(decision.priority),
                  fontSize: "11px",
                  height: 24,
                  "& .MuiChip-icon": {
                    color: getPriorityColor(decision.priority),
                    marginLeft: "8px",
                  },
                }}
              />
              <Chip
                icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
                label={decision.status}
                size="small"
                sx={{
                  backgroundColor: `${getStatusColor(decision.status)}15`,
                  color: getStatusColor(decision.status),
                  fontSize: "11px",
                  height: 24,
                  "& .MuiChip-icon": {
                    color: getStatusColor(decision.status),
                    marginLeft: "8px",
                  },
                }}
              />
            </Box>
            <Typography sx={{ color: "#86868b", fontSize: "12px" }}>
              {formatDistanceToNow(parseISO(decision.deadline), {
                addSuffix: true,
              })}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default DecisionSupportWidget;
