import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import PsychologyIcon from "@mui/icons-material/Psychology";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface QoLInsight {
  title: string;
  description: string;
  category: string;
  impact: "high" | "medium" | "low";
}

export type QualityOfLifeInsightsWidgetData = {
  insights: QoLInsight[];
};

export type QualityOfLifeInsightsWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeInsightsWidgetData;
};

const QualityOfLifeInsightsWidget: React.FC<
  QualityOfLifeInsightsWidgetProps
> = ({ data: { insights }, interactionsComponent }) => {
  const getImpactColor = (impact: string) => {
    switch (impact) {
      case "high":
        return "#32D74B";
      case "medium":
        return "#FF9F0A";
      case "low":
        return "#86868b";
      default:
        return "#86868b";
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        p: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <PsychologyIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Life Quality Insights
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      {insights.map((insight, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: "#f5f5f7",
            borderRadius: "16px",
            padding: 2,
            marginBottom: index !== insights.length - 1 ? 2 : 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginBottom: 1,
            }}
          >
            <TipsAndUpdatesIcon
              sx={{ color: getImpactColor(insight.impact), fontSize: 20 }}
            />
            <Typography
              sx={{ fontSize: "15px", fontWeight: 500, color: "#1d1d1f" }}
            >
              {insight.title}
            </Typography>
          </Box>
          <Typography
            sx={{ fontSize: "13px", color: "#86868b", marginBottom: 1.5 }}
          >
            {insight.description}
          </Typography>
          <Chip
            label={insight.category}
            size="small"
            sx={{
              backgroundColor: "#ffffff",
              color: "#86868b",
              fontSize: "11px",
              height: 24,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default QualityOfLifeInsightsWidget;
