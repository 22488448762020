import WidgetsIcon from "@mui/icons-material/Widgets";
import { Box, Card, Typography } from "@mui/material";
import { Dashboard } from "../../services/dasboard";
import DeleteDashboardDialog from "./DeleteDashboardDialog";
import EditDashboardDialog from "./EditDashboardDialog";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { formatDistanceToNow, parseISO } from "date-fns";

const DashboardCard = ({ name, created_at, widgets, id }: Dashboard) => {
  const formattedDate = formatDistanceToNow(parseISO(created_at), {
    addSuffix: true,
  });

  return (
    <Card
      sx={{
        background: "linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)",
        borderRadius: "16px",
        p: 4,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
        transition: "all 0.2s ease-in-out",
        cursor: "pointer",
        position: "relative",
        overflow: "hidden",
        "&:hover": {
          boxShadow: "0 5px 17.5px rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2.5,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography
              sx={{
                fontSize: "22px",
                fontWeight: 600,
                color: "#1a1a1a",
                mb: 2,
                lineHeight: 1.4,
                letterSpacing: "-0.01em",
              }}
            >
              {name}
            </Typography>

            <Box
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "center",
                },
                gap: 1,
                opacity: 0.85,
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.75,
                  padding: "4px 12px",
                  backgroundColor: "#32D74B15",
                  borderRadius: "12px",
                }}
              >
                <WidgetsIcon sx={{ color: "#32D74B", fontSize: 16 }} />
                <Typography
                  sx={{ color: "#32D74B", fontSize: "13px", fontWeight: 500 }}
                >
                  {widgets.length} widgets
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.75,
                  padding: "4px 12px",
                }}
              >
                <AccessTimeIcon sx={{ color: "GrayText", fontSize: 16 }} />
                <Typography
                  sx={{ color: "GrayText", fontSize: "13px", fontWeight: 500 }}
                >
                  {formattedDate}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <EditDashboardDialog dashboard={{ id, name }} />
          <DeleteDashboardDialog id={id} />
        </Box>
      </Box>
    </Card>
  );
};

export default DashboardCard;
