import { Box, CircularProgress, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { logout } from "../../../services/auth";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

type LogoutButtonProps = {
  onMenuClose: () => void;
};

const LogoutButton = ({ onMenuClose }: LogoutButtonProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);

    const { message, success } = await logout();

    if (!success) {
      enqueueSnackbar(message, { variant: "error" });
      setLoading(false);

      return;
    }

    await queryClient.invalidateQueries({ queryKey: ["auth"] });
    setLoading(false);
    enqueueSnackbar(message, { variant: "success" });
    onMenuClose();
    navigate("/auth/login");
  };

  return (
    <MenuItem onClick={handleClick} disabled={loading}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <LogoutIcon fontSize="small" sx={{ color: "warning.main" }} />
        <span>Logout</span>
        {loading && <CircularProgress size={16} sx={{ ml: 6 }} />}
      </Box>
    </MenuItem>
  );
};

export default LogoutButton;
