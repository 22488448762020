import { Navigate, useParams } from "react-router-dom";
import WidgetsList from "../components/WidgetsList";

const DashboardPage = () => {
  const { id } = useParams();

  if (!id) {
    return <Navigate to="/" />;
  }

  return <WidgetsList dashboardId={id} />;
};

export default DashboardPage;
