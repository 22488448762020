import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createDashboard } from "../../../services/dasboard";
import { enqueueSnackbar } from "notistack";
import DashboardForm from "../../DashboardForm";
import { DashboardFields } from "../../../validations/dashboard";

type CreateDashboardFormProps = {
  onClose: () => void;
};

const CreateDashboardForm = ({ onClose }: CreateDashboardFormProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync } = useMutation({
    mutationFn: createDashboard,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards", "list"] });

      enqueueSnackbar(data?.message ?? "Dashboard created successfully", {
        variant: "success",
      });

      onClose();
    },
    onError: ({ message }) => {
      enqueueSnackbar(message, { variant: "error" });
    },
  });

  const handleSubmit = async (data: DashboardFields) => {
    await mutateAsync(data);
  };

  return <DashboardForm onSubmit={handleSubmit} />;
};

export default CreateDashboardForm;
