import { Box } from "@mui/material";
import SpacesList from "../components/DashboardsList";

const Spaces = () => {
  return (
    <Box
      sx={{
        maxWidth: 1000,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <SpacesList />
    </Box>
  );
};

export default Spaces;
