import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface Affirmation {
  text: string;
  category: string;
  impact: number;
  trend: number;
  color: string;
  bg_color: string;
}

export type QualityOfLifeAffirmationsWidgetData = {
  affirmations: Affirmation[];
};

export type QualityOfLifeAffirmationsWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeAffirmationsWidgetData
};

const QualityOfLifeAffirmationsWidget: React.FC<
  QualityOfLifeAffirmationsWidgetProps
> = ({ data, interactionsComponent }) => {
  const { affirmations } = data;

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const current = affirmations[currentIndex];

  const handleRefresh = () => {
    setCurrentIndex((prev) => (prev + 1) % affirmations.length);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AutoAwesomeRoundedIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Daily Affirmations
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          backgroundColor: current.bg_color,
          borderRadius: "16px",
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            color: "#1d1d1f",
            marginBottom: 2,
            lineHeight: 1.4,
          }}
        >
          {current.text}
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <FavoriteRoundedIcon
                sx={{ color: current.color, fontSize: 16 }}
              />
              <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
                {current.category}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                justifyContent: "flex-end",
              }}
            >
              <TrendingUpIcon sx={{ color: "#32D74B", fontSize: 16 }} />
              <Typography
                sx={{ color: "#32D74B", fontSize: "12px", fontWeight: 500 }}
              >
                {current.trend}%
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
          Impact Score: {current.impact}%
        </Typography>
        <IconButton
          data-no-dnd="true"
          onClick={handleRefresh}
          sx={{
            color: "#86868b",
            "&:hover": {
              backgroundColor: "#f5f5f7",
              color: "#1d1d1f",
            },
          }}
        >
          <RefreshRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default QualityOfLifeAffirmationsWidget;
