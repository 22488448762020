import BedtimeIcon from "@mui/icons-material/Bedtime";
import NightsStayIcon from "@mui/icons-material/NightsStay";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";

type SleepMetric = {
  label: "Sleep Duration" | "Bedtime" | "Wake Time";
  value: string;
  trend: number;
  color: string;
  quality: number;
}

type OverallScore = {
  value: number;
  trend: number;
}

export type QualityOfLifeSleepWidgetData = {
  overall_score: OverallScore;
  metrics: SleepMetric[];
}

type QualityOfLifeSleepWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeSleepWidgetData;
}

const QualityOfLifeSleepWidget: React.FC<QualityOfLifeSleepWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { overall_score, metrics } = data;

  const getIconForLabel = (label: SleepMetric['label']) => {
    switch (label) {
      case 'Sleep Duration':
        return <NightsStayIcon sx={{ fontSize: 20 }} />;
      case 'Bedtime':
        return <BedtimeIcon sx={{ fontSize: 20 }} />;
      case 'Wake Time':
        return <WbTwilightIcon sx={{ fontSize: 20 }} />;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <NightsStayIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Sleep Quality
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              color: "#1d1d1f",
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {overall_score.value}%
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                color: overall_score.trend > 0 ? "#32D74B" : "#FF2D55",
                backgroundColor: overall_score.trend > 0 ? "#32D74B15" : "#FF2D5515",
                padding: "4px 8px",
                borderRadius: "8px",
                fontWeight: 500,
              }}
            >
              {overall_score.trend > 0 ? "+" : ""}
              {overall_score.trend}%
            </Typography>
          </Typography>
          <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
            Overall Sleep Score
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                backgroundColor: "#f5f5f7",
                borderRadius: "16px",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box sx={{ color: metric.color }}>{getIconForLabel(metric.label)}</Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#1d1d1f",
                    }}
                  >
                    {metric.value}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <TrendingUpIcon
                    sx={{
                      color: metric.trend > 0 ? "#32D74B" : "#FF2D55",
                      fontSize: 16,
                      transform: metric.trend < 0 ? "rotate(180deg)" : "none",
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{
                      color: metric.trend > 0 ? "#32D74B" : "#FF2D55",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {metric.trend > 0 ? "+" : ""}
                    {metric.trend}%
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
                  {metric.label}
                </Typography>
                <Box
                  sx={{
                    width: "80px",
                    height: "4px",
                    backgroundColor: "#e5e5e7",
                    borderRadius: "2px",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    sx={{
                      width: `${metric.quality}%`,
                      height: "100%",
                      backgroundColor: metric.color,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QualityOfLifeSleepWidget;
