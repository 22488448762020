import { Alert, AlertTitle, Box, Pagination, Typography } from "@mui/material";
import SortableWidgetsList from "./SortableWidgetsList";
import WidgetSkeleton from "./WidgetSkeleton";
import { getDashboardWidgets } from "../../services/widget";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

type WidgetsListProps = {
  dashboardId: string;
};

const WidgetsList = ({ dashboardId }: WidgetsListProps) => {
  const [page, setPage] = useState(1);

  const { data, isPending, error, isLoading } = useQuery({
    queryKey: ["dashboard", dashboardId, page],
    queryFn: () =>
      getDashboardWidgets({
        dashboard_id: dashboardId,
        page,
        page_size: 10,
      }),
  });

  if (isLoading || (isPending && !data)) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: 2,
        }}
      >
        {[...Array(3)].map((_, index) => (
          <WidgetSkeleton key={index} />
        ))}
      </Box>
    );
  }

  if (error) {
    return (
      <Alert
        severity="error"
        sx={{ "& .MuiAlert-icon": { mt: 0.4 }, maxWidth: 800, mx: "auto" }}
      >
        <AlertTitle>
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            Error
          </Typography>
        </AlertTitle>
        <Typography variant="body1" component="div">
          {error?.message ?? "Failed to get widgets"}
        </Typography>
      </Alert>
    );
  }

  if (!data || !data.items?.length) {
    return (
      <Typography sx={{ textAlign: "center", mt: 4, fontSize: "1.25rem" }}>
        No widgets found
      </Typography>
    );
  }

  const { items: widgets, pagination } = data;

  return (
    <>
      <SortableWidgetsList
        page={page}
        widgets={widgets}
        dashboardId={dashboardId}
      />
      {pagination.total_pages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 6 }}>
          <Pagination
            size="large"
            count={pagination.total_pages}
            page={page}
            onChange={(_, page) => {
              setPage(page);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default WidgetsList;
