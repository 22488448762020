import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import NavigationLink from "./NavigationLink";

const navigation = [
  { name: "Workspace", href: "/workspace" },
  { name: "Stream", href: "/stream" },
  { name: "Spaces", href: "/" },
];

const NavigationMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getCurrentSection = useCallback(() => {
    const path = location.pathname;
    return navigation.find((item) => path.startsWith(item.href))?.name;
  }, [location.pathname]);

  const [selectedSection, setSelectedSection] = useState(getCurrentSection());

  useEffect(() => {
    setSelectedSection(getCurrentSection());
  }, [location.pathname, getCurrentSection]);

  const handleNavigationChange = (event: SelectChangeEvent) => {
    const href = navigation.find(
      (item) => item.name === event.target.value
    )?.href;

    if (!href) return;

    setSelectedSection(event.target.value);
    navigate(href);
  };

  if (isMobile) {
    return (
      <FormControl size="small" sx={{ minWidth: 150 }}>
        <Select
          value={selectedSection}
          onChange={handleNavigationChange}
          variant="outlined"
          displayEmpty
          renderValue={(selected) => selected || ""}
        >
          {navigation.map((item) => (
            <MenuItem value={item.name} key={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      {navigation.map((item) => (
        <NavigationLink key={item.name} path={item.href} label={item.name} />
      ))}
    </Box>
  );
};

export default NavigationMenu;
