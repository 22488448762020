import { AppBar, Box, Toolbar } from "@mui/material";
import Logo from "./Logo";
import NavigationMenu from "./NavigationMenu";
import ProfileMenu from "./ProfileMenu";

const Header = () => {
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
        borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: { xs: 2, sm: 3 },
          py: 1,
          position: "relative",
        }}
      >
        <Logo />

        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <NavigationMenu />
        </Box>
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
