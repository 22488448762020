import SpeedIcon from "@mui/icons-material/Speed";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { BaseWidgetProps } from "../../types/WidgetProps";

type MonthlyTarget = {
  value: number;
  trend: "up" | "down";
};

type PerformanceMetric = {
  label: string;
  value: string;
  color: string;
};

export type PerformanceWidgetData = {
  monthlyTarget: MonthlyTarget;
  progressPercent: number;
  metrics: PerformanceMetric[];
};

type PerformanceWidgetProps = BaseWidgetProps & {
  data: PerformanceWidgetData;
};

const PerformanceWidget: React.FC<PerformanceWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { monthlyTarget, progressPercent, metrics } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SpeedIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Performance
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              color: "#1d1d1f",
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {monthlyTarget.value}%{" "}
            {monthlyTarget.trend === "up" ? (
              <TrendingUpIcon
                sx={{
                  color: "#32D74B",
                  fontSize: 24,
                }}
              />
            ) : (
              <TrendingDownIcon
                sx={{
                  color: "#FF3B30",
                  fontSize: 24,
                }}
              />
            )}
          </Typography>
          <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
            Monthly Target
          </Typography>
        </Box>

        <Box sx={{ position: "relative", width: 60, height: 60 }}>
          <CircularProgress
            variant="determinate"
            value={89.2}
            size={60}
            sx={{
              color: "#32D74B",
              "& .MuiCircularProgress-circle": {
                strokeLinecap: "round",
              },
            }}
          />
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: "13px",
              fontWeight: 500,
              color: "#1d1d1f",
            }}
          >
            {progressPercent}%
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={2} sx={{ marginBottom: 3 }}>
        {metrics.map((item) => (
          <Grid item xs={4} key={item.label}>
            <Box
              sx={{
                backgroundColor: "#f5f5f7",
                padding: 1.5,
                borderRadius: "12px",
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  color: item.color,
                  fontSize: "15px",
                  fontWeight: 600,
                  marginBottom: 1,
                }}
              >
                {item.value}
              </Typography>
              <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PerformanceWidget;
