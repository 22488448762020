import { createContext, useContext } from "react";
import { Widget } from "../../types/Widget";

export type Message = {
  text?: string;
  isUser: boolean;
  widget?: Widget | null;
  replyTo?: Widget | null;
  timestamp: Date;
};

type StreamContextType = {
  messages: Message[];
  handleSend: (message: { content: string; replyTo: Widget | null }) => void;
  isFirstWidgetInSequence: boolean;
  activeWidget: Widget | null;
  setActiveWidget: (widget: Widget | null) => void;
  setWidgetLike: (widgetId: string, isLiked: boolean) => void;
  setWidgetDislike: (widgetId: string, isDisliked: boolean) => void;
};

export const StreamContext = createContext<StreamContextType | null>(null);

export const useStreamContext = () => {
  const context = useContext(StreamContext);

  if (!context) {
    throw new Error("useStreamContext must be used within a StreamProvider");
  }

  return context;
};
