import axios from "axios";
import { NavigateFunction } from "react-router-dom";

declare module "axios" {
  export interface AxiosRequestConfig {
    skipInterceptor?: boolean;
  }
}

export const authInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_API_URL,
  withCredentials: true,
});

export const initializeInterceptors = (navigate: NavigateFunction) => {
  authInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.config && error.config.skipInterceptor) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        navigate("/auth/login");
      }

      return Promise.reject(error);
    }
  );
};
