import React from "react";
import { Box, Typography, LinearProgress, Chip } from "@mui/material";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { BaseWidgetProps } from "../../types/WidgetProps";

type Agent = {
  name: string;
  evolution: number;
  learning_rate: number;
  type: string;
  color: string;
}

export type AgentEvolutionWidgetData = {
  agents: Agent[];
};

export type AgentEvolutionWidgetProps = BaseWidgetProps & {
  data: AgentEvolutionWidgetData;
};

const AgentEvolutionWidget = ({
  data: { agents },
  interactionsComponent,
}: AgentEvolutionWidgetProps) => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        p: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <PsychologyAltIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Agent Evolution
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Typography
        variant="h3"
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          color: "#1d1d1f",
          marginBottom: 2,
        }}
      >
        Learning Progress
      </Typography>

      {agents.map((agent, index) => (
        <Box
          key={index}
          sx={{ marginBottom: index !== agents.length - 1 ? 2.5 : 0 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 1,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "15px",
                  fontWeight: 500,
                  color: "#1d1d1f",
                  marginBottom: 0.5,
                }}
              >
                {agent.name}
              </Typography>
              <Chip
                label={agent.type}
                size="small"
                sx={{
                  backgroundColor: "#f5f5f7",
                  color: "#86868b",
                  fontSize: "11px",
                  height: 20,
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              <AutoFixHighIcon sx={{ color: agent.color, fontSize: 16 }} />
              <Typography sx={{ color: agent.color, fontSize: "13px" }}>
                +{agent.learning_rate}%
              </Typography>
            </Box>
          </Box>
          <LinearProgress
            variant="determinate"
            value={agent.evolution}
            sx={{
              backgroundColor: "#f5f5f7",
              borderRadius: 2,
              height: 8,
              "& .MuiLinearProgress-bar": {
                backgroundColor: agent.color,
                borderRadius: 2,
              },
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default AgentEvolutionWidget;
