import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Tooltip, IconButton } from "@mui/material";

type DislikeWidgetProps = {
  isDisliked: boolean;
  onClick?: () => void;
};

const DislikeWidget = ({ isDisliked, onClick }: DislikeWidgetProps) => {
  return (
    <Tooltip title={isDisliked ? "Remove dislike" : "Dislike widget"}>
      <IconButton
        size="small"
        sx={{
          color: isDisliked ? "#FF3B30" : "#86868b",
          "&:hover": { color: "#FF3B30" },
        }}
        onClick={onClick}
      >
        <ThumbDownIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default DislikeWidget;
