import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";

export const getStatusStyles = (isLiked: boolean, isDisliked: boolean) =>
  ({
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "20px",
      border: "2px solid",
      borderColor: isLiked
        ? "rgba(52, 199, 89, 0.2)"
        : isDisliked
        ? "rgba(255, 59, 48, 0.2)"
        : "transparent",
      pointerEvents: "none",
      zIndex: 1,
    },
    "&::after":
      isLiked || isDisliked
        ? {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: "20px",
            backgroundColor: isLiked
              ? "rgba(52, 199, 89, 0.04)"
              : "rgba(255, 59, 48, 0.04)",
            pointerEvents: "none",
          }
        : undefined,
  } as SxProps<Theme>);

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomElementsFromArray = (array: any[], count: number) => {
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};
