import { Box } from "@mui/material";
import { Widget } from "../../../types/Widget";
import ReplyTo from "./ReplyTo";

const MessageContent = ({
  text,
  isUser,
  replyTo,
  timestamp,
  handleClick,
}: {
  text?: string;
  isUser: boolean;
  replyTo?: Widget | null;
  timestamp: Date;
  handleClick: (id: string) => void;
}) => {
  if (!text && !replyTo) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: isUser ? "flex-end" : "flex-start",
        maxWidth: { xs: "90%", sm: "70%", lg: "40%" },
      }}
    >
      <Box
        sx={{
          backgroundColor: isUser ? "#0A84FF" : "#ffffff",
          color: isUser ? "#ffffff" : "#1d1d1f",
          padding: replyTo ? "16px" : "12px 16px",
          borderRadius: "16px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          mb: 0.5,
        }}
      >
        {replyTo ? (
          <ReplyTo replyTo={replyTo} handleClick={handleClick} text={text} />
        ) : null}
        {text}
      </Box>
      <Box sx={{ fontSize: "0.75rem", color: "#86868b" }}>
        {timestamp.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
      </Box>
    </Box>
  );
};

export default MessageContent;
