import { Box } from "@mui/material";
import { Widget } from "../../../types/Widget";
import { Message as TMessage } from "../StreamContext";
import MessageContent from "./MessageContent";
import WidgetContainer from "./WidgetContainer";

const Message = ({ text, isUser, widget, timestamp, replyTo }: TMessage) => {
  const handleClick = (id: string) => {
    const widgetElement = document.getElementById(id);
    widgetElement?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  if (!text && !replyTo && !widget) return null;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: isUser ? "flex-end" : "flex-start",
        justifyContent: isUser ? "flex-end" : "flex-start",
      }}
    >
      <MessageContent
        text={text}
        isUser={isUser}
        replyTo={replyTo}
        timestamp={timestamp}
        handleClick={handleClick}
      />
      <WidgetContainer widget={widget as Widget} />
    </Box>
  );
};

export default Message;
