import { useState } from "react";
import DislikeWidget from "../WidgetsInteractions/DislikeWidget";
import LikeWidget from "../WidgetsInteractions/LikeWidget";
import ShareMenu from "../WidgetsInteractions/ShareMenu";
import SpacesMenu from "../WidgetsInteractions/SpacesMenu";
import WidgetInteractionsMenu from "../WidgetsInteractions/WidgetInteractionsMenu";
import { useUpdateWidgetReaction } from "./useUpdateWidgetReaction";

type DashboardWidgetInteractionsProps = {
  dashboardId: string;
  widgetId: string;
  isLiked: boolean;
  isDisliked: boolean;
  page: number;
};

const DashboardWidgetInteractions = ({
  dashboardId,
  widgetId,
  isLiked,
  isDisliked,
  page,
}: DashboardWidgetInteractionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { mutate: updateWidgetReaction } = useUpdateWidgetReaction({
    dashboardId,
    page,
  });

  return (
    <WidgetInteractionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
      <LikeWidget
        isLiked={isLiked}
        onClick={() =>
          updateWidgetReaction({
            widget_id: widgetId,
            is_liked: !isLiked,
            is_disliked: false,
          })
        }
      />
      <DislikeWidget
        isDisliked={isDisliked}
        onClick={() =>
          updateWidgetReaction({
            widget_id: widgetId,
            is_liked: false,
            is_disliked: !isDisliked,
          })
        }
      />
      <ShareMenu />
      <SpacesMenu widgetId={widgetId} />
    </WidgetInteractionsMenu>
  );
};

export default DashboardWidgetInteractions;
