import { Box, Typography } from "@mui/material";
import RegisterForm from "../components/RegisterForm";
import { MindbotsLogoInfinity } from "../components/icons/MindbotsLogos";

const Register = () => {
  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <MindbotsLogoInfinity
        color="primary"
        sx={{ fontSize: 60, textAlign: "center", mb: 2 }}
      />
      <Typography
        variant="h4"
        component="h1"
        textAlign="center"
        sx={{
          fontSize: 30,
          fontWeight: "bold",
          color: (theme) => theme.palette.primary.main,
          mb: 1,
        }}
      >
        Create an Account
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
        sx={{
          color: "text.secondary",
          mb: 6,
        }}
      >
        Please enter your details to register.
      </Typography>
      <RegisterForm />
    </Box>
  );
};

export default Register;
