import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import TimerIcon from "@mui/icons-material/Timer";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface SavingMetric {
  label: string;
  value: string;
  subtext: string;
  trend: number;
}

export type AutomationSavingsWidgetData = {
  metrics: SavingMetric[];
};

type AutomationSavingsWidgetProps = BaseWidgetProps & {
  data: AutomationSavingsWidgetData;
};

const AutomationSavingsWidget: React.FC<AutomationSavingsWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { metrics } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TimerIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Automation Savings
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Grid container spacing={2}>
        {metrics.map((metric, index) => (
          <Grid item xs={6} key={index}>
            <Box
              sx={{
                backgroundColor: "#fafafa",
                borderRadius: "16px",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  marginBottom: 1,
                }}
              >
                {metric.label === "Time Saved" ? (
                  <AccessTimeIcon sx={{ color: "#32D74B", fontSize: 20 }} />
                ) : (
                  <AutorenewIcon sx={{ color: "#0A84FF", fontSize: 20 }} />
                )}
                <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
                  {metric.label}
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: "#1d1d1f",
                  marginBottom: 0.5,
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                {metric.value}
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <TrendingUpIcon sx={{ color: "#32D74B", fontSize: 16 }} />
                  <Typography sx={{ color: "#32D74B", fontSize: "13px" }}>
                    {metric.trend}%
                  </Typography>
                </Box>
              </Typography>

              <Typography sx={{ color: "#86868b", fontSize: "12px" }}>
                {metric.subtext}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default AutomationSavingsWidget;
