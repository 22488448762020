import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, FormHelperText, Typography } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useLocalStorage } from "usehooks-ts";
import { verifyEmail } from "../services/auth";
import { VerifyEmailFields, verifyEmailSchema } from "../validations/auth";
import { useNavigate } from "react-router-dom";

const VerifyEmailForm = () => {
  const navigate = useNavigate();
  const [username, , removeUsername] = useLocalStorage("username", "");

  const form = useForm<VerifyEmailFields>({
    resolver: zodResolver(verifyEmailSchema),
    defaultValues: {
      verification_code: "",
    },
  });

  const onSubmit = async ({ verification_code }: VerifyEmailFields) => {
    const { message, success } = await verifyEmail({
      verification_code,
      username,
    });

    if (!success) {
      enqueueSnackbar(message, {
        variant: "error",
      });
      return;
    }

    enqueueSnackbar(message, {
      variant: "success",
    });

    removeUsername();
    navigate("/auth/login");
    form.reset();
  };

  const { isSubmitting } = form.formState;

  return (
    <Box>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        textAlign="center"
        sx={{
          mb: 4,
          fontWeight: "bold",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        Verify Email
      </Typography>

      <Typography
        variant="body1"
        textAlign="center"
        sx={{
          mb: 6,
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        Enter the verification code we sent to your email. If you didn't receive
        it, click the resend button.
      </Typography>

      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <Controller
          name="verification_code"
          control={form.control}
          rules={{ validate: (value) => value.length === 6 }}
          render={({ field, fieldState }) => (
            <Box sx={{ mb: 4 }}>
              <MuiOtpInput sx={{ gap: 1 }} {...field} length={6} />
              {fieldState.invalid ? (
                <FormHelperText error sx={{ mt: 1 }}>
                  {fieldState.error?.message}
                </FormHelperText>
              ) : null}
            </Box>
          )}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          disabled={isSubmitting}
          sx={{
            py: 1.5,
            textTransform: "none",
            fontSize: "1.1rem",
            fontWeight: "bold",
          }}
        >
          {isSubmitting ? "Verifying..." : "Verify"}
        </Button>
      </form>
    </Box>
  );
};

export default VerifyEmailForm;
