import { useQuery } from "@tanstack/react-query";
import { checkAuth } from "../../services/auth";

export const useAuth = () => {
  return useQuery({
    queryKey: ["auth"],
    queryFn: checkAuth,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
