import { Box, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import ActionButtons from "./ActionButtons";
import ActiveWidget from "./ActiveWidget";
import MessageTextField from "./MessageTextField";
import { useStreamContext } from "../StreamContext";

const InputPanel = styled(Paper)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: "#f5f5f7",
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  "&.active-widget": {
    backgroundColor: "#f1f1f3",
  }
}));

const StyledInputContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(2), 
  maxWidth: 1000,
  width: "100%",
  paddingLeft: theme.spacing(0), 
  paddingRight: theme.spacing(0),
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MessageInput = () => {
  const [message, setMessage] = useState("");
  const [isLocked, setIsLocked] = useState(false);
  const { handleSend, activeWidget, setActiveWidget } = useStreamContext();
  const inputRef = useRef<HTMLInputElement>(null);

  const sendHandler = () => {
    const messageData = {
      content: message,
      replyTo: activeWidget,
    };

    handleSend(messageData);
    setMessage("");
    setActiveWidget(null);
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeWidget]);

  return (
    <InputPanel className={activeWidget ? "active-widget" : ""}>
      <StyledInputContainer>
        <ActiveWidget />
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            backgroundColor: "#ffffff",
            borderRadius: { xs: "16px", sm: "20px" },
            width: "100%",
            outline: "1px solid #e0e0e0",
            "&:focus-within": {
              outlineWidth: "1.5px",
            }
          }}
        >
          <MessageTextField
            inputRef={inputRef}
            value={message}
            setValue={setMessage}
            onSend={sendHandler}
            placeholder={
              activeWidget ? "React to the widget..." : "Type your message..."
            }
          />
          <ActionButtons
            isLocked={isLocked}
            setIsLocked={setIsLocked}
            canSend={!!message.trim()}
            onSend={sendHandler}
          />
        </Box>
      </StyledInputContainer>
    </InputPanel>
  );
};

export default MessageInput;
