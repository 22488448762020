import { z } from "zod";

const passwordSchema = z
  .string()
  .min(8, "Password must be at least 8 characters long")
  .max(100, "Password must be at most 100 characters long")
  .refine((val) => /[A-Z]/.test(val), {
    message: "Password must contain at least one uppercase letter",
  })
  .refine((val) => /[a-z]/.test(val), {
    message: "Password must contain at least one lowercase letter",
  })
  .refine((val) => /\d/.test(val), {
    message: "Password must contain at least one digit",
  })
  .refine((val) => /[\W_]/.test(val), {
    message: "Password must contain at least one symbol",
  })
  .refine((val) => !/\s/.test(val), {
    message: "Password must not contain spaces",
  });

const birthdateSchema = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}$/, {
    message: "Please select your birthdate",
  })
  .refine(
    (dateString) => {
      try {
        const date = new Date(dateString);
        return (
          !isNaN(date.getTime()) &&
          dateString === date.toISOString().split("T")[0]
        );
      } catch {
        return false;
      }
    },
    {
      message:
        "Invalid date. Ensure the value is a real date in DD-MM-YYYY format.",
    }
  );

export const registerSchema = z.object({
  password: passwordSchema,
  email: z.string().email(),
  givenName: z.string().optional().nullable(),
  familyName: z.string().optional().nullable(),
  birthdate: birthdateSchema,
  acceptTerms: z.boolean().refine((val) => val, {
    message: "You must accept the Terms of Services",
  }),
});

export type RegisterFields = z.infer<typeof registerSchema>;

export const verifyEmailSchema = z.object({
  verification_code: z
    .string()
    .length(6, { message: "Verification code must be 6 characters long" }),
});

export type VerifyEmailFields = z.infer<typeof verifyEmailSchema>;

export const loginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "Password is required" }),
});

export type LoginFields = z.infer<typeof loginSchema>;
