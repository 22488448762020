import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { login } from "../services/auth";
import { LoginFields, loginSchema } from "../validations/auth";
import { useQueryClient } from "@tanstack/react-query";

const LoginForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [, setUsername] = useLocalStorage("username", "");

  const form = useForm<LoginFields>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: LoginFields) => {
    const result = await login(data);

    if (!result.success) {
      enqueueSnackbar(result.message, { variant: "error" });

      if (result.redirect) {
        setUsername(data.email);
        navigate(result.redirect);
      }

      return;
    }

    await queryClient.invalidateQueries({ queryKey: ["auth"] });
    enqueueSnackbar(result.message, { variant: "success" });
    await queryClient.setQueryData(["check-auth"], { is_authenticated: true });
    form.reset();
    navigate("/");
  };

  const { errors, isSubmitting } = form.formState;

  return (
    <Box>
      <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              {...form.register("email")}
              label="Email"
              type="email"
              required
              fullWidth
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              {...form.register("password")}
              label="Password"
              type="password"
              fullWidth
              required
              error={!!errors.password}
              helperText={errors.password?.message}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 1.3 }}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              size="large"
              disabled={isSubmitting}
              sx={{
                py: 1.5,
                textTransform: "none",
                fontSize: "1.1rem",
                fontWeight: "bold",
              }}
            >
              {isSubmitting ? "Logging in..." : "Login"}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              textAlign="center"
              sx={{
                color: "text.secondary",
              }}
            >
              Don&apos;t have an account?{" "}
              <Link
                to="/auth/register"
                style={{
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                Sign up
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default LoginForm;
