import { useState } from "react";
import { Widget } from "../../types/Widget";
import { Message, StreamContext } from "./StreamContext";
import { getRandomElementsFromArray, getRandomNumber } from "./utils";
import widgetsData from "../../data/widgets.json";

const StreamProvider = ({ children }: { children: React.ReactNode }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isFirstWidgetInSequence, setIsFirstWidgetInSequence] = useState(true);
  const [activeWidget, setActiveWidget] = useState<Widget | null>(null);

  const handleSend = (message: { content: string; replyTo: Widget | null }) => {
    if (message.content.trim()) {
      setIsFirstWidgetInSequence(true);
      setMessages([
        ...messages,
        {
          text: message.content,
          isUser: true,
          replyTo: message.replyTo,
          timestamp: new Date(),
        },
      ]);

      const randomNumber = getRandomNumber(1, 4);
      const randomWidgets = getRandomElementsFromArray(
        widgetsData.widgets,
        randomNumber
      );

      randomWidgets.forEach((widget, index) => {
        setTimeout(() => {
          setIsFirstWidgetInSequence(index === 0);
          setMessages((prev) => [
            ...prev,
            {
              widget: widget as Widget,
              isUser: false,
              timestamp: new Date(),
            },
          ]);
        }, 1000 + index * 1000);
      });
    }
  };

  const setWidgetLike = (widgetId: string, isLiked: boolean) => {
    if (activeWidget?.id === widgetId) {
      setActiveWidget((prev) =>
        prev
          ? {
              ...prev,
              isLiked,
              isDisliked: isLiked ? false : prev.is_disliked,
            }
          : null
      );
    }

    setMessages((prevMessages) =>
      prevMessages.map((message) => {
        if (message.widget?.id === widgetId) {
          return {
            ...message,
            widget: {
              ...message.widget,
              isLiked,
              isDisliked: isLiked ? false : message.widget.is_disliked,
            },
          };
        }

        if (message.replyTo?.id === widgetId) {
          return {
            ...message,
            replyTo: {
              ...message.replyTo,
              isLiked,
              isDisliked: isLiked ? false : message.replyTo.is_disliked,
            },
          };
        }
        return message;
      })
    );
  };

  const setWidgetDislike = (widgetId: string, isDisliked: boolean) => {
    if (activeWidget?.id === widgetId) {
      setActiveWidget((prev) =>
        prev
          ? {
              ...prev,
              isDisliked,
              isLiked: isDisliked ? false : prev.is_liked,
            }
          : null
      );
    }

    setMessages((prevMessages) =>
      prevMessages.map((message) => {
        if (message.widget?.id === widgetId) {
          return {
            ...message,
            widget: {
              ...message.widget,
              isDisliked,
              isLiked: isDisliked ? false : message.widget.is_liked,
            },
          };
        }

        if (message.replyTo?.id === widgetId) {
          return {
            ...message,
            replyTo: {
              ...message.replyTo,
              isDisliked,
              isLiked: isDisliked ? false : message.replyTo.is_liked,
            },
          };
        }
        return message;
      })
    );
  };

  return (
    <StreamContext.Provider
      value={{
        messages,
        handleSend,
        isFirstWidgetInSequence,
        activeWidget,
        setActiveWidget,
        setWidgetLike,
        setWidgetDislike,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export default StreamProvider;
