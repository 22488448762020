import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface Affirmation {
  text: string;
  author: string;
  color: string;
  bg_color: string;
}

export type AffirmationWidgetData = {
  affirmations: Affirmation[];
};

export type AffirmationWidgetProps = BaseWidgetProps & {
  data: AffirmationWidgetData;
};

const AffirmationWidget: React.FC<AffirmationWidgetProps> = ({
  data,
  interactionsComponent,
}) => {
  const { affirmations } = data;

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const current = affirmations[currentIndex];

  const handleRefresh = () => {
    setCurrentIndex((prev) => (prev + 1) % affirmations.length);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AutoAwesomeRoundedIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Daily Affirmation
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          backgroundColor: current.bg_color,
          borderRadius: "16px",
          padding: 2,
          position: "relative",
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            color: "#1d1d1f",
            marginBottom: 1,
            lineHeight: 1.4,
          }}
        >
          {current.text}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <FavoriteRoundedIcon sx={{ color: current.color, fontSize: 16 }} />
          <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
            {current.author}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          data-no-dnd="true"
          onClick={handleRefresh}
          sx={{
            color: "#86868b",
            "&:hover": {
              backgroundColor: "#f5f5f7",
              color: "#1d1d1f",
            },
          }}
        >
          <RefreshRoundedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AffirmationWidget;
