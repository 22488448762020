import { Box } from "@mui/material";
import { useStreamContext } from "../StreamContext";
import ActiveWidgetInteractions from "./ActiveWidgetInteractions";
import { renderWidget } from "../../../utils/renderWidget";

const ActiveWidget = () => {
  const { activeWidget } = useStreamContext();

  if (!activeWidget) return null;

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        width: "100%",
        maxWidth: { xs: "100%", lg: "60%" },
        minWidth: { xs: "100%", sm: 400 },
        maxHeight: "60vh",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <Box
        sx={{
          minWidth: 400,
          zoom: 0.7,
        }}
      >
        {renderWidget(activeWidget, <ActiveWidgetInteractions />)}
      </Box>
    </Box>
  );
};

export default ActiveWidget;
