import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import {
  GetDashboardWidgetsResponse,
  updateWidgetReaction,
} from "../../services/widget";

type UseUpdateWidgetReactionProps = {
  dashboardId: string;
  page: number;
};

export const useUpdateWidgetReaction = ({
  dashboardId,
  page,
}: UseUpdateWidgetReactionProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateWidgetReaction,
    onMutate: async ({ widget_id, is_disliked, is_liked }) => {
      await queryClient.cancelQueries({
        queryKey: ["dashboard", dashboardId, page],
      });

      const previousData = queryClient.getQueryData<
        GetDashboardWidgetsResponse["data"]
      >(["dashboard", dashboardId, page]) ?? { items: [] };

      const { items: previousWidgets } = previousData;

      const newWidgets = previousWidgets.map((widget) => {
        if (widget.id === widget_id) {
          return {
            ...widget,
            is_liked: is_liked,
            is_disliked: is_disliked,
          };
        }
        return widget;
      });

      const newData = { ...previousData, items: newWidgets };
      queryClient.setQueryData(["dashboard", dashboardId, page], newData);

      return { previousData };
    },
    onError: (error, __, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(
          ["dashboard", dashboardId, page],
          context.previousData
        );
      }

      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["dashboard", dashboardId, page],
      });
    },
  });
};
