import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MindbotsLogoInfinity: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 4c-2.5 0-4.5 2-4.5 4.5S9.5 13 12 13s4.5-2 4.5-4.5S14.5 4 12 4zm0 7c-1.4 0-2.5-1.1-2.5-2.5S10.6 6 12 6s2.5 1.1 2.5 2.5S13.4 11 12 11z"
      opacity="0.9"
    />
    <path
      d="M7.5 11c-1.9 0-3.5 1.6-3.5 3.5S5.6 18 7.5 18s3.5-1.6 3.5-3.5S9.4 11 7.5 11zm9 0c-1.9 0-3.5 1.6-3.5 3.5s1.6 3.5 3.5 3.5 3.5-1.6 3.5-3.5-1.6-3.5-3.5-3.5z"
    />
    <path
      d="M7.5 13c.8 0 1.5.7 1.5 1.5S8.3 16 7.5 16 6 15.3 6 14.5 6.7 13 7.5 13zm9 0c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5z"
      fill="currentColor"
    />
  </SvgIcon>
); 