import EditIcon from "@mui/icons-material/Edit";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";
import EditDashboardForm from "./EditDashboardForm";
import { Dashboard } from "../../services/dasboard";

interface EditSpaceDialogProps {
  dashboard: Pick<Dashboard, "id" | "name">;
}

const EditSpaceDialog = ({ dashboard }: EditSpaceDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleEvent = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleEvent(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton color="primary" onClick={handleClick} aria-label="edit">
        <Tooltip title="Edit">
          <EditIcon />
        </Tooltip>
      </IconButton>
      <Dialog
        data-no-dnd="true"
        open={open}
        onClick={handleEvent}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "0.7rem",
            p: {
              xs: 3,
              sm: 4,
            },
            maxWidth: "375px",
            mx: 2,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", p: 0, mb: 4 }}>
          <Typography variant="h5" component="div">
            Edit Space
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 0, pt: "5px !important" }}>
          <EditDashboardForm dashboard={dashboard} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EditSpaceDialog;
