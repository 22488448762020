import { Box } from "@mui/material";
import { Widget } from "../../../types/Widget";
import { renderWidget } from "../../../utils/renderWidget";
import StreamWidgetInteractions from "./StreamWidgetInteractions";

const WidgetContainer = ({ widget }: { widget: Widget }) => {
  if (!widget) return null;

  return (
    <Box
      id={`widget-${widget.id}`}
      sx={{
        maxWidth: { xs: "90%", lg: "60%" },
        minWidth: { xs: 300, sm: 400 },
        position: "relative",
      }}
    >
      {renderWidget(
        widget as Widget,
        <StreamWidgetInteractions widget={widget as Widget} />
      )}
    </Box>
  );
};

export default WidgetContainer;
