import React from "react";
import { Box, Typography, CircularProgress, Grid } from "@mui/material";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import FavoriteIcon from "@mui/icons-material/Favorite";
import TimerIcon from "@mui/icons-material/Timer";
import { BaseWidgetProps } from "../../types/WidgetProps";

export type ActivityWidgetData = {
  steps: string;
  steps_progress: string;
  active_time: string;
  heart_rate: string;
  calories_burned: string;
  goal_progress: string;
};

export type ActivityWidgetProps = BaseWidgetProps & {
  data: ActivityWidgetData;
};

const ActivityWidget: React.FC<ActivityWidgetProps> = ({
  data,
  interactionsComponent,
}) => {
  const {
    steps,
    steps_progress,
    active_time,
    heart_rate,
    calories_burned,
    goal_progress,
  } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#fcfcfc",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          marginBottom: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <DirectionsRunIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Activity
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <Box>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              color: "#1d1d1f",
              marginBottom: 1,
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {steps.toLocaleString()}
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                color: "#32D74B",
                backgroundColor: "#32D74B15",
                padding: "4px 8px",
                borderRadius: "8px",
                fontWeight: 500,
              }}
            >
              +{steps_progress}%
            </Typography>
          </Typography>
          <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
            Steps today
          </Typography>
        </Box>

        <Box
          sx={{ position: "relative", display: "flex", alignItems: "center" }}
        >
          <CircularProgress
            variant="determinate"
            value={+goal_progress}
            size={60}
            sx={{
              color: "#FF9F0A",
              "& .MuiCircularProgress-circle": {
                strokeLinecap: "round",
              },
            }}
          />
          <LocalFireDepartmentIcon
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              color: "#FF9F0A",
              fontSize: 24,
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={2}>
        {[
          {
            icon: <TimerIcon sx={{ color: "#0A84FF", fontSize: 20 }} />,
            value: active_time,
            label: "Active Time",
            bgColor: "#0A84FF15",
          },
          {
            icon: <FavoriteIcon sx={{ color: "#FF2D55", fontSize: 20 }} />,
            value: heart_rate,
            label: "BPM",
            bgColor: "#FF2D5515",
          },
          {
            icon: (
              <LocalFireDepartmentIcon
                sx={{ color: "#FF9F0A", fontSize: 20 }}
              />
            ),
            value: calories_burned,
            label: "kcal",
            bgColor: "#FF9F0A15",
          },
        ].map((item) => (
          <Grid item xs={4} key={item.label}>
            <Box
              sx={{
                backgroundColor: "#fcfcfc",
                padding: 1.5,
                borderRadius: "12px",
                textAlign: "center",
              }}
            >
              {item.icon}
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#86868b",
                  fontWeight: 500,
                }}
              >
                {item.value}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#86868b",
                }}
              >
                {item.label}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ActivityWidget;
