import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import SettingsIcon from "@mui/icons-material/Settings";
import { Avatar, Box, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import { useAuth } from "../useAuth";

const profileMenuItems = [
  {
    name: "Profile Settings",
    href: "/",
    icon: <SettingsIcon fontSize="small" sx={{ color: "primary.main" }} />,
  },
  {
    name: "Email Management",
    href: "/",
    icon: <EmailIcon fontSize="small" sx={{ color: "secondary.main" }} />,
  },
  {
    name: "Password Settings",
    href: "/",
    icon: <LockIcon fontSize="small" sx={{ color: "error.main" }} />,
  },
  {
    name: "Profile Picture",
    href: "/",
    icon: <AccountBoxIcon fontSize="small" sx={{ color: "info.main" }} />,
  },
];

const ProfileMenu = () => {
  const { data } = useAuth();

  const [profileAnchorEl, setProfileAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  let userName = "U";
  if (data && data.user) {
    userName = data.user.username.charAt(0);
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Avatar
        onClick={handleProfileMenuOpen}
        sx={{
          bgcolor: "secondary.main",
          cursor: "pointer",
          width: 40,
          height: 40,
          "&:hover": {
            bgcolor: "secondary.dark",
          },
        }}
      >
        {userName}
      </Avatar>
      <Menu
        anchorEl={profileAnchorEl}
        open={Boolean(profileAnchorEl)}
        onClose={handleProfileMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 2,
            minWidth: 200,
            py: "4px",
          },
          "& .MuiMenu-paper": {
            borderRadius: "12px",
          },
        }}
      >
        {profileMenuItems.map((item) => (
          <MenuItem
            key={item.name}
            component={Link}
            to={item.href}
            onClick={handleProfileMenuClose}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              {item.icon}
              {item.name}
            </Box>
          </MenuItem>
        ))}
        <LogoutButton onMenuClose={handleProfileMenuClose} />
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
