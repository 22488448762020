import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Chip, Typography } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";
import { BaseWidgetProps } from "../../types/WidgetProps";

export type MarkdownWidgetData = {
  markdown: string;
  completed_notes: number;
  tags: string[];
};

type MarkdownWidgetProps = BaseWidgetProps & {
  data: MarkdownWidgetData;
};

const MarkdownWidget: React.FC<MarkdownWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { markdown, completed_notes, tags } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <ArticleOutlinedIcon sx={{ color: "#86868b" }} />
            <Typography
              sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
            >
              Notes
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Chip
            label={`${completed_notes} completed`}
            size="small"
            icon={<CheckCircleIcon sx={{ fontSize: 16 }} />}
            sx={{
              backgroundColor: "#32D74B15",
              color: "#32D74B",
              fontSize: "11px",
              height: 24,
              "& .MuiChip-icon": { color: "#32D74B" },
            }}
          />
          {interactionsComponent}
        </Box>
      </Box>

      <Box
        sx={{
          "& h1": {
            fontSize: "20px",
            fontWeight: 600,
            color: "#1d1d1f",
            marginBottom: 1.5,
            marginTop: 0,
          },
          "& h2": {
            fontSize: "15px",
            fontWeight: 600,
            color: "#1d1d1f",
            marginBottom: 1,
            marginTop: 2,
          },
          "& p": {
            fontSize: "14px",
            color: "#424245",
            marginBottom: 1,
          },
          "& ul": {
            paddingLeft: 2,
            marginTop: 0,
            marginBottom: 1,
          },
          "& li": {
            fontSize: "14px",
            color: "#424245",
            marginBottom: 0.5,
          },
          "& blockquote": {
            borderLeft: "3px solid #0A84FF",
            margin: "16px 0",
            padding: "8px 16px",
            backgroundColor: "#0A84FF10",
            borderRadius: "4px",
            "& p": {
              margin: 0,
              color: "#0A84FF",
              fontWeight: 500,
            },
          },
          "& a": {
            color: "#0A84FF",
            textDecoration: "none",
          },
        }}
      >
        <ReactMarkdown>{markdown}</ReactMarkdown>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: 1,
          marginTop: 2,
          flexWrap: "wrap",
        }}
      >
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            size="small"
            sx={{
              backgroundColor: "#f0f0f0",
              color: "#86868b",
              fontSize: "11px",
              height: 24,
              "& .MuiChip-icon": { color: "#86868b" },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MarkdownWidget;
