import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import { Box, IconButton, Menu, Tooltip } from "@mui/material";
import { useState } from "react";
import CreateDashboardDialog from "./CreateDashboardDialog";
import SpacesList from "./SpacesList";

type SpacesMenuProps = {
  widgetId: string;
};

const SpacesMenu = ({ widgetId }: SpacesMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Manage spaces">
        <IconButton
          data-no-dnd="true"
          size="small"
          onClick={handleMenuOpen}
          sx={{
            color: "#86868b",
            "&:hover": {
              color: "#0A84FF",
              transition: "all 0.2s ease-in-out",
            },
          }}
        >
          <LibraryAddCheckIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        data-no-dnd="true"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: 2,
            minWidth: 220,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            py: "4px",
            maxHeight: "280px",
            minHeight: "150px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiMenu-paper": {
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
            py: 1,
          },
          "& .MuiList-root": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flex: 1,
          },
        }}
      >
        <SpacesList widgetId={widgetId} />
        <Box sx={{ px: 2, mt: 2, mb: "6px" }}>
          <CreateDashboardDialog />
        </Box>
      </Menu>
    </>
  );
};

export default SpacesMenu;
