import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

// Variant 1: Minimalistic Brain + Circuit
export const MindbotsIconCircuit: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      opacity="0.2"
    />
    <path
      d="M12 4c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2s2-.9 2-2V6c0-1.1-.9-2-2-2zm4 4c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2s2-.9 2-2v-2c0-1.1-.9-2-2-2zm-4 4c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2s2-.9 2-2v-2c0-1.1-.9-2-2-2z"
    />
  </SvgIcon>
);

// Variant 2: Abstract Bot Face
export const MindbotsIconFace: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      opacity="0.2"
    />
    <circle cx="8" cy="9" r="2" />
    <circle cx="16" cy="9" r="2" />
    <path
      d="M12 16c-1.48 0-2.75-.81-3.45-2h6.9c-.7 1.19-1.97 2-3.45 2z"
    />
  </SvgIcon>
); 