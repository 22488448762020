import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect } from "react";
import { useState } from "react";
import { Widget } from "../../types/Widget";
import { DragEndEvent } from "@dnd-kit/core";
import {
  GetDashboardWidgetsResponse,
  updateWidgetOrder,
} from "../../services/widget";
import { arrayMove } from "@dnd-kit/sortable";
import { enqueueSnackbar } from "notistack";

type UseSortableWidgetsProps = {
  widgets: Widget[];
  dashboardId: string;
  page: number;
};

export const useSortableWidgets = ({
  widgets,
  dashboardId,
  page,
}: UseSortableWidgetsProps) => {
  const queryClient = useQueryClient();
  const [localWidgets, setLocalWidgets] = useState(widgets);

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      const { active, over } = event;
      
      if (!over || active.id === over.id) {
        return;
      }

      await queryClient.cancelQueries({ queryKey: ["dashboard", dashboardId, page] });

      const previousData = queryClient.getQueryData<
        GetDashboardWidgetsResponse["data"]
      >(["dashboard", dashboardId, page]);

      if (!previousData) {
        return;
      }

      const { items: previousWidgets } = previousData;

      let oldIndex = -1;
      let newIndex = -1;
      let newPosition = -1;

      previousWidgets.forEach((widget, index) => {
        if (widget.id === active.id) {
          oldIndex = index;
        }
        if (widget.id === over.id) {
          newIndex = index;
          newPosition = widget.position;
        }
      });

      if (oldIndex === -1 || newIndex === -1 || newPosition === -1) {
        return;
      }

      const newWidgets = arrayMove(previousWidgets, oldIndex, newIndex);

      setLocalWidgets(newWidgets);

      queryClient.setQueryData(["dashboard", dashboardId, page], {
        ...previousData,
        items: newWidgets,
      });

      try {
        await updateWidgetOrder({
          position: newPosition,
          widget_id: active.id as string,
          dashboard_id: dashboardId,
        });
      } catch (error) {
        enqueueSnackbar((error as Error).message, { variant: "error" });
        queryClient.setQueryData(["dashboard", dashboardId, page], previousData);
      } finally {
        await queryClient.invalidateQueries({
          queryKey: ["dashboard", dashboardId, page],
        });
      }
    },
    [queryClient, dashboardId, page]
  );

  useEffect(() => {
    setLocalWidgets(widgets);
  }, [widgets]);

  return {
    handleDragEnd,
    localWidgets,
  };
};
