import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SpaIcon from "@mui/icons-material/Spa";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface BalanceMetric {
  category: "Work" | "Life";
  hours: number;
  trend: number;
  color: string;
  activities: Array<{
    name: string;
    time: number;
  }>;
}

export type QualityOfLifeBalanceWidgetData = {
  metrics: BalanceMetric[];
};

type QualityOfLifeBalanceWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeBalanceWidgetData;
};

const QualityOfLifeBalanceWidget: React.FC<QualityOfLifeBalanceWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { metrics } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SpaIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Work-Life Balance
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Grid container spacing={2}>
        {metrics.map((metric, index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                backgroundColor: "#f5f5f7",
                borderRadius: "16px",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 1.5,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box sx={{ color: metric.color }}>
                    {metric.category === "Work" ? (
                      <WorkIcon sx={{ fontSize: 20 }} />
                    ) : (
                      <SpaIcon sx={{ fontSize: 20 }} />
                    )}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#1d1d1f",
                    }}
                  >
                    {metric.category}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <TrendingUpIcon
                    sx={{
                      color: metric.trend > 0 ? "#32D74B" : "#FF2D55",
                      fontSize: 16,
                      transform: metric.trend < 0 ? "rotate(180deg)" : "none",
                    }}
                  />
                  <Typography
                    sx={{
                      color: metric.trend > 0 ? "#32D74B" : "#FF2D55",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {Math.abs(metric.trend)}%
                  </Typography>
                </Box>
              </Box>

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  color: metric.color,
                  marginBottom: 1.5,
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                {metric.hours}
                <Typography
                  component="span"
                  sx={{ fontSize: "13px", color: "#86868b" }}
                >
                  hours
                </Typography>
              </Typography>

              {metric.activities.map((activity, idx) => (
                <Box
                  key={idx}
                  sx={{
                    marginBottom: idx !== metric.activities.length - 1 ? 1 : 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: 0.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "13px", color: "#1d1d1f" }}>
                      {activity.name}
                    </Typography>
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <AccessTimeIcon sx={{ color: "#86868b", fontSize: 14 }} />
                      <Typography sx={{ fontSize: "13px", color: "#86868b" }}>
                        {activity.time}h
                      </Typography>
                    </Box>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={(activity.time / metric.hours) * 100}
                    sx={{
                      height: 4,
                      borderRadius: 2,
                      backgroundColor: "#E5E5EA",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: metric.color,
                        borderRadius: 2,
                      },
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QualityOfLifeBalanceWidget;
