import { Checkbox, CircularProgress, ListItemText } from "@mui/material";
import { MenuItem } from "@mui/material";
import { Dashboard } from "../../../services/dasboard";
import { useAddWidget } from "./useAddWidget";
import { useRemoveWidget } from "./useRemoveWidget";

type SpaceItemProps = {
  dashboard: Pick<Dashboard, "id" | "name" | "widgets">;
  widgetId: string;
};

const SpaceItem = ({ dashboard, widgetId }: SpaceItemProps) => {
  const { mutate: addWidget, isPending: isAddingWidget } = useAddWidget();
  const { mutate: removeWidget, isPending: isRemovingWidget } =
    useRemoveWidget();

  const { id: dashboardId, name: dashboardName } = dashboard;

  const handleToggleSpace = (dashboardId: string, isChecked: boolean) => {
    console.log("isChecked", isChecked);
    if (isChecked) {
      addWidget({ dashboardId, widgetId });
    } else {
      
      removeWidget({ dashboardId, widgetId });
    }
  };

  const handleMenuItemClick = (e: React.MouseEvent<HTMLElement>) => {
    const checkbox = e.currentTarget.querySelector(
      'input[type="checkbox"]'
    ) as HTMLInputElement;

    checkbox.click();
  };

  const isWidgetInDashboard = dashboard.widgets.includes(widgetId);

  return (
    <MenuItem
      key={dashboardId}
      onClick={handleMenuItemClick}
      disabled={isAddingWidget || isRemovingWidget}
      sx={{
        "&:hover": {
          backgroundColor: "rgba(10, 132, 255, 0.08)",
        },
        padding: "4px 8px",
        cursor: "pointer",
        "&.Mui-disabled": {
          opacity: 0.7,
        },
      }}
    >
      <Checkbox
        checked={isWidgetInDashboard}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleToggleSpace(dashboardId, e.target.checked)}
        disabled={isAddingWidget || isRemovingWidget}
        sx={{
          color: "#86868b",
          "&.Mui-checked": {
            color: "#0A84FF",
          },
        }}
      />
      <ListItemText
        primary={dashboardName}
        sx={{
          "& .MuiTypography-root": {
            fontSize: "0.95rem",
            fontWeight: 400,
          },
        }}
      />
      {(isAddingWidget || isRemovingWidget) && (
        <CircularProgress sx={{ mr: 2 }} size={20} />
      )}
    </MenuItem>
  );
};

export default SpaceItem;
