import AirRoundedIcon from "@mui/icons-material/AirRounded";
import CloudRoundedIcon from "@mui/icons-material/CloudRounded";
import ThunderstormRoundedIcon from "@mui/icons-material/ThunderstormRounded";
import WaterDropRoundedIcon from "@mui/icons-material/WaterDropRounded";
import WbSunnyRoundedIcon from "@mui/icons-material/WbSunnyRounded";
import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface WeatherDay {
  day: string;
  temp: number;
  minTemp: number;
  icon: React.ReactNode;
}

const WeatherWidget: React.FC<BaseWidgetProps> = ({
  interactionsComponent,
}) => {
  const forecast: WeatherDay[] = [
    {
      day: "Mon",
      temp: 22,
      minTemp: 15,
      icon: <WbSunnyRoundedIcon sx={{ color: "#FF9F0A", fontSize: 38 }} />,
    },
    {
      day: "Tue",
      temp: 23,
      minTemp: 16,
      icon: <CloudRoundedIcon sx={{ color: "#86868b", fontSize: 38 }} />,
    },
    {
      day: "Wed",
      temp: 24,
      minTemp: 17,
      icon: <ThunderstormRoundedIcon sx={{ color: "#0A84FF", fontSize: 38 }} />,
    },
    {
      day: "Thu",
      temp: 25,
      minTemp: 18,
      icon: <WbSunnyRoundedIcon sx={{ color: "#FF9F0A", fontSize: 38 }} />,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        p: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AirRoundedIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Weather
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          marginBottom: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <WbSunnyRoundedIcon sx={{ color: "#FF9F0A", fontSize: 52 }} />
          <Box>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 600,
                fontSize: "32px",
                color: "#1d1d1f",
                marginBottom: 0.5,
              }}
            >
              23°C
            </Typography>
            <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
              Partly Cloudy
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              marginBottom: 0.5,
            }}
          >
            <WaterDropRoundedIcon sx={{ color: "#0A84FF", fontSize: 16 }} />
            <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
              41% humidity
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <AirRoundedIcon sx={{ color: "#86868b", fontSize: 16 }} />
            <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
              23 mph wind
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {forecast.map((day) => (
          <Card
            key={day.day}
            sx={{
              flex: 1,
              backgroundColor: "#f5f5f7",
              borderRadius: "12px",
              padding: 1.5,
              textAlign: "center",
              boxShadow: "none",
            }}
          >
            <Typography
              sx={{ color: "#86868b", fontSize: "13px", marginBottom: 1 }}
            >
              {day.day}
            </Typography>
            {day.icon}
            <Box sx={{ marginTop: 1 }}>
              <Typography
                sx={{ color: "#1d1d1f", fontSize: "15px", fontWeight: 500 }}
              >
                {day.temp}°
              </Typography>
              <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
                {day.minTemp}°
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default WeatherWidget;
