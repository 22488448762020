import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import StarIcon from "@mui/icons-material/Star";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";

type LifeMetricCategory =
  | "Energy Level"
  | "Mood"
  | "Life Balance"
  | "Wellbeing";

type LifeMetricStatus = "Great" | "Good" | "Okay" | "Low";

type LifeMetric = {
  category: LifeMetricCategory;
  status: LifeMetricStatus;
  message: string;
  color: string;
};

type Overall = {
  status: LifeMetricStatus;
  message: string;
};

export type QualityOfLifeWidgetData = {
  overall: Overall;
  metrics: LifeMetric[];
};

type QualityOfLifeWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeWidgetData;
};

const QualityOfLifeWidget: React.FC<QualityOfLifeWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { overall, metrics } = data;

  const getIconByCategory = (category: LifeMetricCategory) => {
    switch (category) {
      case "Energy Level":
        return <BatteryChargingFullIcon />;
      case "Mood":
        return <EmojiEmotionsIcon />;
      case "Life Balance":
        return <SelfImprovementIcon />;
      case "Wellbeing":
        return <FavoriteIcon />;
      default:
        return <StarIcon />;
    }
  };

  const getStatusColor = (status: LifeMetricStatus) => {
    switch (status) {
      case "Great":
        return "#32D74B";
      case "Good":
        return "#FF9F0A";
      case "Okay":
        return "#0A84FF";
      case "Low":
        return "#86868b";
      default:
        return "#86868b";
    }
  };

  const getStatusEmoji = (status: LifeMetricStatus) => {
    switch (status) {
      case "Great":
        return "⭐⭐⭐";
      case "Good":
        return "⭐⭐";
      case "Okay":
        return "⭐";
      case "Low":
        return "✨";
      default:
        return "✨";
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <StarIcon sx={{ color: "#FF9F0A" }} />
          <Typography
            sx={{ color: "#1d1d1f", fontSize: "15px", fontWeight: 500 }}
          >
            Your Daily Wellness Check
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          backgroundColor: "#fafafa",
          borderRadius: "16px",
          padding: 2,
          marginBottom: 2,
          textAlign: "center",
        }}
      >
        <Typography
          sx={{ fontSize: "15px", color: "#86868b", marginBottom: 1 }}
        >
          Overall, you're having a
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            color: "#32D74B",
            marginBottom: 1,
          }}
        >
          {overall.status} Day! ✨
        </Typography>
        <Typography sx={{ fontSize: "13px", color: "#86868b" }}>
          {overall.message}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {metrics.map((metric, index) => (
          <Grid item xs={6} key={index}>
            <Tooltip title={metric.message} arrow placement="top">
              <Box
                sx={{
                  backgroundColor: "#fafafa",
                  borderRadius: "16px",
                  padding: 2,
                  textAlign: "center",
                  cursor: "pointer",
                  transition: "transform 0.2s",
                  "&:hover": {
                    transform: "translateY(-2px)",
                  },
                }}
              >
                <Box sx={{ color: metric.color, marginBottom: 1 }}>
                  {getIconByCategory(metric.category)}
                </Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#1d1d1f",
                    marginBottom: 0.5,
                  }}
                >
                  {metric.category}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: getStatusColor(metric.status),
                    fontWeight: 500,
                  }}
                >
                  {getStatusEmoji(metric.status)}
                </Typography>
              </Box>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QualityOfLifeWidget;
