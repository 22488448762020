import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Tooltip } from "@mui/material";
import { useStreamContext } from "../StreamContext";

const ActiveWidgetInteractions = () => {
  const { setActiveWidget } = useStreamContext();

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Tooltip title="Close widget">
        <IconButton
          onClick={() => setActiveWidget(null)}
          sx={{
            color: "#A1A1A1",
            "&:hover": {
              backgroundColor: "#A1A1A120",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ActiveWidgetInteractions;
