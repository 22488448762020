import MoodIcon from "@mui/icons-material/Mood";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";

type MoodMetric = {
  label: "Positive" | "Neutral" | "Negative";
  value: number;
  trend: number;
  color: string;
};

type OverallScore = {
  value: number;
  trend: number;
};

export type QualityOfLifeMoodWidgetData = {
  overall_score: OverallScore;
  moods: MoodMetric[];
};

type QualityOfLifeMoodWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeMoodWidgetData;
};

const QualityOfLifeMoodWidget: React.FC<QualityOfLifeMoodWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { overall_score, moods } = data;

  const getMoodIcon = (label: MoodMetric["label"]) => {
    switch (label) {
      case "Positive":
        return <MoodIcon sx={{ fontSize: 20 }} />;
      case "Neutral":
        return <SentimentSatisfiedIcon sx={{ fontSize: 20 }} />;
      case "Negative":
        return <SentimentVeryDissatisfiedIcon sx={{ fontSize: 20 }} />;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <MoodIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Emotional Balance
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box sx={{ marginBottom: 3 }}>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: "32px",
            color: "#1d1d1f",
            marginBottom: 1,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {overall_score.value}%
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              color: overall_score.trend > 0 ? "#32D74B" : "#FF2D55",
              backgroundColor: overall_score.trend > 0 ? "#32D74B15" : "#FF2D5515",
              padding: "4px 8px",
              borderRadius: "8px",
              fontWeight: 500,
            }}
          >
            {overall_score.trend > 0 ? "+" : ""}{overall_score.trend}%
          </Typography>
        </Typography>
        <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
          Positive Mood Score
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {moods.map((mood, index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                backgroundColor: "#f5f5f7",
                borderRadius: "16px",
                padding: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Box sx={{ color: mood.color }}>
                    {getMoodIcon(mood.label)}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#1d1d1f",
                    }}
                  >
                    {mood.label}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                  <TrendingUpIcon
                    sx={{
                      color: mood.trend > 0 ? "#32D74B" : "#FF2D55",
                      fontSize: 16,
                      transform: mood.trend < 0 ? "rotate(180deg)" : "none",
                    }}
                  />
                  <Typography
                    sx={{
                      color: mood.trend > 0 ? "#32D74B" : "#FF2D55",
                      fontSize: "12px",
                      fontWeight: 500,
                    }}
                  >
                    {Math.abs(mood.trend)}%
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <LinearProgress
                  variant="determinate"
                  value={mood.value}
                  sx={{
                    flex: 1,
                    height: 8,
                    borderRadius: 4,
                    backgroundColor: "#E5E5EA",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: mood.color,
                      borderRadius: 4,
                    },
                  }}
                />
                <Typography
                  sx={{
                    color: mood.color,
                    fontSize: "13px",
                    fontWeight: 500,
                  }}
                >
                  {mood.value}%
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default QualityOfLifeMoodWidget;
