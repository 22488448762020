import { GridItemSize } from "../components/WidgetsList/GridItem";
import { ActivityWidgetData } from "../components/widgets/ActivityWidget";
import { AffirmationWidgetData } from "../components/widgets/AffirmationWidget";
import { AgentEvolutionWidgetData } from "../components/widgets/AgentEvolutionWidget";
import { AutomationSavingsWidgetData } from "../components/widgets/AutomationSavingsWidget";
import { CalendarWidgetData } from "../components/widgets/CalendarWidget";
import { DecisionSupportWidgetData } from "../components/widgets/DecisionSupportWidget";
import { FinanceWidgetData } from "../components/widgets/FinanceWidget";
import { GoalsWidgetData } from "../components/widgets/GoalsWidget";
import { MarkdownWidgetData } from "../components/widgets/MarkdownWidget";
import { MindbotsInsightWidgetData } from "../components/widgets/MindbotsInsightWidget";
import { NewsWidgetData } from "../components/widgets/NewsWidget";
import { PerformanceWidgetData } from "../components/widgets/PerformanceWidget";
import { QualityOfLifeAffirmationsWidgetData } from "../components/widgets/QualityOfLifeAffirmationsWidget";
import { QualityOfLifeBalanceWidgetData } from "../components/widgets/QualityOfLifeBalanceWidget";
import { QualityOfLifeHabitsWidgetData } from "../components/widgets/QualityOfLifeHabitsWidget";
import { QualityOfLifeInsightsWidgetData } from "../components/widgets/QualityOfLifeInsightsWidget";
import { QualityOfLifeMoodWidgetData } from "../components/widgets/QualityOfLifeMoodWidget";
import { QualityOfLifeSleepWidgetData } from "../components/widgets/QualityOfLifeSleepWidget";
import { QualityOfLifeWidgetData } from "../components/widgets/QualityOfLifeWidget";
import { QuoteWidgetData } from "../components/widgets/QuoteWidget";
import { TaskCompletionWidgetData } from "../components/widgets/TaskCompletionWidget";

export const WIDGET_TYPES = {
  qualityOfLife: "quality_of_life",
  qualityOfLifeInsights: "quality_of_life_insight",
  qualityOfLifeMood: "quality_of_life_mood",
  qualityOfLifeSleep: "quality_of_life_sleep",
  qualityOfLifeBalance: "quality_of_life_balance",
  qualityOfLifeAffirmations: "quality_of_life_affirmations",
  performanceMetrics: "performance",
  activityTracker: "activity",
  agentEvolution: "agent_evolution",
  automationSavings: "automation_savings",
  calendarOverview: "calendar",
  decisionSupport: "decision_support",
  goalsTracker: "goals",
  markdownNotes: "markdown",
  mindbotsInsights: "mindbots_insight",
  taskCompletion: "task_completion",
  weatherInfo: "weather_info",
  newsFeed: "news",
  financeOverview: "finance",
  dailyQuote: "quote",
  calendarDetailed: "calendar_detailed",
  qualityOfLifeHabits: "quality_of_life_habits",
  affirmations: "affirmation",
} as const;

export type WidgetType = (typeof WIDGET_TYPES)[keyof typeof WIDGET_TYPES];

type BaseWidget = {
  id: string;
  size?: GridItemSize;
  is_liked: boolean;
  is_disliked: boolean;
  position: number;
};

type ActivityWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.activityTracker;
  data: ActivityWidgetData;
};

type AgentEvolutionWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.agentEvolution;
  data: AgentEvolutionWidgetData;
};

type DecisionSupportWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.decisionSupport;
  data: DecisionSupportWidgetData;
};

type GoalsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.goalsTracker;
  data: GoalsWidgetData;
};

type MindbotsInsightWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.mindbotsInsights;
  data: MindbotsInsightWidgetData;
};

type NewsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.newsFeed;
  data: NewsWidgetData;
};

type QualityOfLifeAffirmationsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLifeAffirmations;
  data: QualityOfLifeAffirmationsWidgetData;
};

type QualityOfLifeInsightsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLifeInsights;
  data: QualityOfLifeInsightsWidgetData;
};

type QuoteWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.dailyQuote;
  data: QuoteWidgetData;
};

type AutomationSavingsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.automationSavings;
  data: AutomationSavingsWidgetData;
};

type CalendarWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.calendarOverview;
  data: CalendarWidgetData;
};

type FinanceWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.financeOverview;
  data: FinanceWidgetData;
};

type MarkdownWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.markdownNotes;
  data: MarkdownWidgetData;
};

type PerformanceWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.performanceMetrics;
  data: PerformanceWidgetData;
};

type QualityOfLifeBalanceWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLifeBalance;
  data: QualityOfLifeBalanceWidgetData;
};

type QualityOfLifeHabitsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLifeHabits;
  data: QualityOfLifeHabitsWidgetData;
};

type QualityOfLifeMoodWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLifeMood;
  data: QualityOfLifeMoodWidgetData;
};

type QualityOfLifeSleepWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLifeSleep;
  data: QualityOfLifeSleepWidgetData;
};

type QualityOfLifeWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.qualityOfLife;
  data: QualityOfLifeWidgetData;
};

type TaskCompletionWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.taskCompletion;
  data: TaskCompletionWidgetData;
};

type WeatherWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.weatherInfo;
};

type AffirmationsWidget = BaseWidget & {
  type: typeof WIDGET_TYPES.affirmations;
  data: AffirmationWidgetData;
};

export type Widget =
  | ActivityWidget
  | AgentEvolutionWidget
  | DecisionSupportWidget
  | GoalsWidget
  | MindbotsInsightWidget
  | NewsWidget
  | QualityOfLifeAffirmationsWidget
  | QualityOfLifeInsightsWidget
  | QuoteWidget
  | AutomationSavingsWidget
  | CalendarWidget
  | FinanceWidget
  | MarkdownWidget
  | PerformanceWidget
  | QualityOfLifeBalanceWidget
  | QualityOfLifeHabitsWidget
  | QualityOfLifeMoodWidget
  | QualityOfLifeSleepWidget
  | QualityOfLifeWidget
  | TaskCompletionWidget
  | WeatherWidget
  | AffirmationsWidget;
