import { IconButton, Tooltip } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

type LikeWidgetProps = {
  isLiked: boolean;
  onClick?: () => void;
}

const LikeWidget = ({ isLiked, onClick }: LikeWidgetProps) => {
  return (
    <Tooltip title={isLiked ? "Remove like" : "Like widget"}>
      <IconButton
        size="small"
        onClick={onClick}
        sx={{
          color: isLiked ? "#34C759" : "#86868b",
          "&:hover": { color: "#34C759" },
        }}
      >
        <ThumbUpIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default LikeWidget;
