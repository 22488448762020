import { Box } from "@mui/material";
import { Widget } from "../../../types/Widget";
import { renderWidget } from "../../../utils/renderWidget";

const ReplyTo = ({
  replyTo,
  handleClick,
  text,
}: {
  replyTo: Widget;
  handleClick: (id: string) => void;
  text?: string;
}) => {
  if (!replyTo) return null;

  return (
    <Box
      onClick={() => handleClick(`widget-${replyTo.id}`)}
      sx={{
        mb: text ? 2 : 0,
        overflow: "hidden",
        cursor: "pointer",
        minWidth: { xs: 320, sm: 400 },
        position: "relative",
        zoom: 0.7,
        "& > *": { pointerEvents: "none" },
      }}
    >
      {renderWidget(replyTo)}
    </Box>
  );
};

export default ReplyTo;
