import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Box, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";
import { formatDate } from "../../utils/formatDate";

interface NewsItem {
  title: string;
  source: string;
  date: string;
  category: string;
  color: string;
}

export type NewsWidgetData = {
  newsItems: NewsItem[];
};

export type NewsWidgetProps = BaseWidgetProps & {
  data: NewsWidgetData;
};

const NewsWidget: React.FC<NewsWidgetProps> = ({
  data: { newsItems },
  interactionsComponent,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % newsItems.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prev) => (prev - 1 + newsItems.length) % newsItems.length);
  };

  const currentNewsItem = newsItems[currentIndex];
  const { category, color, date, source, title } = currentNewsItem;
  const formattedDate = formatDate(date);

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          gap: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <NewspaperIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            News Feed
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box sx={{ position: "relative", minHeight: "180px" }}>
        {/* News Content */}
        <Box
          sx={{
            backgroundColor: "#f5f5f7",
            borderRadius: "16px",
            padding: 2,
            marginBottom: 2,
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              color: "#1d1d1f",
              marginBottom: 2,
              lineHeight: 1.4,
            }}
          >
            {title}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{ color: "#86868b", fontSize: "13px", marginBottom: 0.5 }}
              >
                {source}
              </Typography>
              <Typography sx={{ color: "#86868b", fontSize: "12px" }}>
                {formattedDate}
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: `${color}15`,
                color: color,
                padding: "4px 12px",
                borderRadius: "12px",
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              {category}
            </Box>
          </Box>
        </Box>

        {/* Navigation Buttons */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handlePrevious}
            sx={{ color: "#86868b" }}
            data-no-dnd="true"
          >
            <NavigateBeforeIcon />
          </IconButton>

          <Box sx={{ display: "flex", gap: 1 }}>
            {newsItems.map((_, index) => (
              <FiberManualRecordIcon
                key={index}
                sx={{
                  fontSize: 8,
                  color: index === currentIndex ? "#1d1d1f" : "#86868b",
                }}
              />
            ))}
          </Box>

          <IconButton
            onClick={handleNext}
            sx={{ color: "#86868b" }}
            data-no-dnd="true"
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default NewsWidget;
