import { Box, IconButton, Tooltip } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";

const ActionButtons = ({
  isLocked,
  setIsLocked,
  canSend,
  onSend,
}: {
  isLocked: boolean;
  setIsLocked: (value: boolean) => void;
  canSend: boolean;
  onSend: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: { xs: "0 4px", sm: "0 8px" },
        mb: { xs: 1.4, sm: 1.6 },
        gap: { xs: 0.5, sm: 1 },
      }}
    >
      <Tooltip title={isLocked ? "Unlock microphone" : "Lock microphone"}>
        <IconButton
          size="small"
          onClick={() => setIsLocked(!isLocked)}
          sx={{
            color: isLocked ? "#FF2D55" : "#A1A1A1",
            "&:hover": {
              backgroundColor: isLocked ? "#FF2D5530" : "#A1A1A120",
            },
          }}
        >
          <LockIcon sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Voice input">
        <span>
          <IconButton
            size="small"
            disabled={isLocked}
            sx={{
              color: isLocked ? "#A1A1A1" : "#0A84FF",
              "&:hover": {
                backgroundColor: isLocked ? "transparent" : "#0A84FF20",
              },
            }}
          >
            <MicIcon sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }} />
          </IconButton>
        </span>
      </Tooltip>
      <Box
        sx={{
          height: { xs: "20px", sm: "26px" },
          borderLeft: "1px solid #E5E5EA",
          margin: { xs: "0 4px", sm: "0 8px" },
        }}
      />
      <Tooltip title={canSend ? "Send message" : "Message is empty"}>
        <span>
          <IconButton
            size="small"
            onClick={onSend}
            disabled={!canSend}
            sx={{
              color: canSend ? "#0A84FF" : "#A1A1A1",
              "&:hover": {
                backgroundColor: canSend ? "#0A84FF20" : "transparent",
              },
            }}
          >
            <SendIcon sx={{ fontSize: { xs: "1.2rem", sm: "1.5rem" } }} />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};

export default ActionButtons;
