import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useRef } from "react";
import EmptyState from "./EmptyState";
import Message from "./Message";
import InputPanel from "./InputPanel";
import { useStreamContext } from "./StreamContext";

const ChatContainer = styled(Box)({
  position: "fixed",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#f5f5f7",
  boxShadow: "none",
});

const MessagesContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  width: "100%",
  overflowY: "auto",
  padding: "104px 16px 10px 16px",
  display: "flex",
  flexDirection: "column",
  maxWidth: 1200,
  marginLeft: "auto",
  marginRight: "auto",
  boxSizing: "border-box",
  "&::-webkit-scrollbar": {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "104px 20px 10px 20px",
  },
}));

const Stream = () => {
  const { messages, isFirstWidgetInSequence } = useStreamContext();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const prevMessagesLengthRef = useRef(messages.length);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (
      isFirstWidgetInSequence &&
      messages.length > prevMessagesLengthRef.current
    ) {
      scrollToBottom();
    }

    prevMessagesLengthRef.current = messages.length;
  }, [messages, isFirstWidgetInSequence]);

  return (
    <ChatContainer>
      <MessagesContainer>
        {messages.length === 0 ? (
          <EmptyState />
        ) : (
          <Box
            sx={{
              pt: "40px",
              gap: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {messages.map((msg, index) => (
              <Message key={index} {...msg} />
            ))}
          </Box>
        )}
        <div ref={messagesEndRef} />
      </MessagesContainer>
      <InputPanel />
    </ChatContainer>
  );
};

export default Stream;
