import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useState } from "react";
import CreateDashboardForm from "./CreateDashboardForm";

const CreateDashboardDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="contained"
        fullWidth
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
      >
        Add space
      </Button>
      <Dialog
        data-no-dnd="true"
        open={open}
        onClose={handleClose}
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "0.7rem",
            p: {
              xs: 3,
              sm: 4,
            },
            maxWidth: "375px",
            mx: 2,
          },
        }}
      >
        <DialogTitle sx={{ textAlign: "center", p: 0, mb: 4 }}>
          <Typography variant="h5" component="div">
            Create New Space
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ p: 0, pt: "5px !important" }}>
          <CreateDashboardForm onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default CreateDashboardDialog;
