import { IconButton, MenuItem, Menu } from "@mui/material";
import { Tooltip } from "@mui/material";
import { MouseEvent, useState } from "react";
import ShareIcon from "@mui/icons-material/Share";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import RedditIcon from "@mui/icons-material/Reddit";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const SHARE_MENU_ITEMS = [
  { icon: TwitterIcon, label: "Twitter", color: "#1DA1F2" },
  { icon: LinkedInIcon, label: "LinkedIn", color: "#0A66C2" },
  { icon: FacebookIcon, label: "Facebook", color: "#1877F2" },
  { icon: RedditIcon, label: "Reddit", color: "#FF4500" },
  { icon: TelegramIcon, label: "Telegram", color: "#26A5E4" },
  { icon: WhatsAppIcon, label: "WhatsApp", color: "#25D366" },
];

const ShareMenu = () => {
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);

  const handleShareMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setShareAnchorEl(event.currentTarget);
  };

  const handleShareMenuClose = () => {
    setShareAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Share widget">
        <IconButton
          size="small"
          sx={{
            color: "#86868b",
            "&:hover": { color: "#0A84FF" },
          }}
          onClick={handleShareMenuOpen}
        >
          <ShareIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Menu
        data-no-dnd="true"
        open={Boolean(shareAnchorEl)}
        onClose={handleShareMenuClose}
        anchorEl={shareAnchorEl}
        sx={{
          "& .MuiMenu-list": {
          width: "175px",
            py: "12px",
            gap: 1,
            display: "flex",
            flexDirection: "column",
          },
          "& .MuiMenu-paper": {
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {SHARE_MENU_ITEMS.map(({ icon: Icon, label, color }) => (
          <MenuItem
            key={label}
            onClick={handleShareMenuClose}
            sx={{ gap: 1 }}
          >
            <Icon fontSize="small" sx={{ color }} />
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ShareMenu;
