import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import { GetDashboardsResponse } from "../../../services/dasboard";
import { addWidgetToDashboard } from "../../../services/widget";

export const useAddWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addWidgetToDashboard,
    onMutate: async ({ dashboardId, widgetId }) => {
      await queryClient.cancelQueries({ queryKey: ["dashboards", "list"] });

      const previousData = queryClient.getQueryData<
        GetDashboardsResponse["data"]
      >(["dashboards", "list"]);

      if (!previousData) return undefined;

      const { items: previousDashboards } = previousData;

      const updatedDashboards = previousDashboards.map((dashboard) => {
        if (dashboard.id === dashboardId) {
          return { ...dashboard, widgets: [...dashboard.widgets, widgetId] };
        }

        return dashboard;
      });

      queryClient.setQueryData(["dashboards", "list"], {
        ...previousData,
        items: updatedDashboards,
      });

      return { previousData };
    },
    onSuccess: () => {
      enqueueSnackbar("Widget added to space", {
        variant: "success",
      });
    },
    onError: (_, __, context) => {
      if (context) {
        queryClient.setQueryData(["dashboards", "list"], context.previousData);
      }

      enqueueSnackbar("Failed to add widget to space", {
        variant: "error",
      });
    },
    onSettled: (_, __, { dashboardId }) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards", "list"] });
      queryClient.invalidateQueries({ queryKey: ["dashboard", dashboardId] });
    },
  });
};
