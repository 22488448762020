import { Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useLocalStorage } from "usehooks-ts";
import { resendVerificationCode } from "../services/auth";
import { enqueueSnackbar } from "notistack";

const ResendCodeForm = () => {
  const [username] = useLocalStorage("username", "");
  const form = useForm();

  const onSubmit = async () => {
    const { success, message } = await resendVerificationCode({ username });

    if (!success) {
      enqueueSnackbar(message, {
        variant: "error",
      });

      return;
    }

    enqueueSnackbar(message, {
      variant: "success",
    });

    form.reset();
  };

  const { isSubmitting } = form.formState;

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Button
        variant="outlined"
        fullWidth
        size="large"
        sx={{
          py: 1.5,
          textTransform: "none",
          fontSize: "1.1rem",
          fontWeight: "bold",
        }}
        disabled={isSubmitting}
        type="submit"
      >
        {isSubmitting ? "Resending..." : "Resend Code"}
      </Button>
    </form>
  );
};

export default ResendCodeForm;
