import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";
import { IconType } from "../../const/iconsRegistry";
import { getIcon } from "../../utils/getIcon";

type HabitMetric = {
  icon: IconType;
  value: string;
  label: string;
  bg_color: string;
  color: string;
};

export type QualityOfLifeHabitsWidgetData = {
  habits: HabitMetric[];
};

type QualityOfLifeHabitsWidgetProps = BaseWidgetProps & {
  data: QualityOfLifeHabitsWidgetData;
};

const QualityOfLifeHabitsWidget: React.FC<QualityOfLifeHabitsWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { habits } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        p: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <SelfImprovementIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Daily Habits
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Grid container spacing={2}>
        {habits.map((habit, index) => {
          const icon = getIcon(habit.icon, { sx: { fontSize: 20 } });

          return (
            <Grid item xs={6} key={index}>
              <Box
                sx={{
                  backgroundColor: habit.bg_color,
                  padding: 2,
                  borderRadius: "16px",
                  textAlign: "center",
                }}
              >
                <Box sx={{ color: habit.color, marginBottom: 1 }}>{icon}</Box>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    color: habit.color,
                    marginBottom: 0.5,
                  }}
                >
                  {habit.value}
                </Typography>
                <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
                  {habit.label}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default QualityOfLifeHabitsWidget;
