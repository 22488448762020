import { useState } from "react";
import { useStreamContext } from "../StreamContext";
import ChatWithWidget from "./ChatWithWidget";
import { Widget } from "../../../types/Widget";
import { Box } from "@mui/material";
import LikeWidget from "../../WidgetsInteractions/LikeWidget";
import DislikeWidget from "../../WidgetsInteractions/DislikeWidget";
import WidgetInteractionsMenu from "../../WidgetsInteractions/WidgetInteractionsMenu";
import ShareMenu from "../../WidgetsInteractions/ShareMenu";
import SpacesMenu from "../../WidgetsInteractions/SpacesMenu";

type StreamWidgetInteractionsProps = {
  widget: Widget;
};

const StreamWidgetInteractions = ({
  widget,
}: StreamWidgetInteractionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setActiveWidget, setWidgetDislike, setWidgetLike } =
    useStreamContext();

  const { is_liked, is_disliked, id } = widget;

  const handleChatClick = () => {
    setActiveWidget(widget);
    setAnchorEl(null);
  };

  const handleWidgetLike = () => {
    setWidgetLike(id, !is_liked);
  };

  const handleWidgetDislike = () => {
    setWidgetDislike(id, !is_disliked);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {is_liked ? (
        <LikeWidget isLiked={is_liked} onClick={handleWidgetLike} />
      ) : null}
      {is_disliked ? (
        <DislikeWidget isDisliked={is_disliked} onClick={handleWidgetDislike} />
      ) : null}
      <WidgetInteractionsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <ChatWithWidget onClick={handleChatClick} />
        <LikeWidget isLiked={is_liked} onClick={handleWidgetLike} />
        <DislikeWidget isDisliked={is_disliked} onClick={handleWidgetDislike} />
        <ShareMenu />
        <SpacesMenu widgetId={id} />
      </WidgetInteractionsMenu>
    </Box>
  );
};

export default StreamWidgetInteractions;
