import { Box, CircularProgress } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import Header from "./Header";
import { useAuth } from "./useAuth";

const Layout = () => {
  const { data, isLoading } = useAuth();

  if (isLoading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#f5f5f7",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.is_authenticated) {
    return <Navigate to="/auth/login" />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: "#f5f5f7",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        component="header"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1100,
          backgroundColor: "#ffffff",
          boxShadow: "0 1px 3px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Header />
      </Box>
      <Box
        component="main"
        sx={{
          flex: 1,
          p: {
            xs: "144px 16px 60px 16px",
            sm: "144px 20px 60px 20px",
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default Layout;
