import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, rectSortingStrategy } from "@dnd-kit/sortable";
import { Widget } from "../../types/Widget";
import { MouseSensor, TouchSensor } from "../../utils/sensors";
import WidgetsGrid from "./WidgetsGrid";
import { useSortableWidgets } from "./useSortableWidgets";

type SortableWidgetsListProps = {
  widgets: Widget[];
  dashboardId: string;
      page: number
};

const SortableWidgetsList = ({
  widgets,
  dashboardId,
  page,
}: SortableWidgetsListProps) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { handleDragEnd, localWidgets } = useSortableWidgets({
    widgets,
    dashboardId,
    page,
  });

  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToParentElement]}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={localWidgets.map((w) => w.id)}
        strategy={rectSortingStrategy}
      >
        <WidgetsGrid
          page={page}
          widgets={localWidgets}
          dashboardId={dashboardId}
        />
      </SortableContext>
    </DndContext>
  );
};

export default SortableWidgetsList;
