import { IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Menu } from "@mui/material";
import { Tooltip } from "@mui/material";
import { MouseEvent, PropsWithChildren } from "react";

type WidgetInteractionsMenuProps = PropsWithChildren & {
  anchorEl: HTMLElement | null;
  setAnchorEl: (anchorEl: HTMLElement | null) => void;
};

const WidgetInteractionsMenu = ({
  children,
  anchorEl,
  setAnchorEl,
}: WidgetInteractionsMenuProps) => {
  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Interactions">
        <IconButton data-no-dnd="true" size="medium" onClick={handleMenuOpen}>
          <MoreHorizIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <Menu
        data-no-dnd="true"
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorEl={anchorEl}
        sx={{
          "& .MuiMenu-list": {
            padding: "10px 16px",
            gap: 1.3,
            display: "flex",
            alignItems: "center",
          },
          "& .MuiMenu-paper": {
            borderRadius: "12px",
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        {children}
      </Menu>
    </>
  );
};

export default WidgetInteractionsMenu;
