import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { MindbotsLogoInfinity } from "../../icons/MindbotsLogos";

const Logo = () => (
  <Link
    to="/"
    style={{
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1.5,
        color: "#0A84FF",
      }}
    >
      <MindbotsLogoInfinity fontSize="large" />
      <Box
        sx={{
          typography: "h5",
          fontWeight: 600,
          display: { xs: "none", md: "block" },
          color: "#1d1d1f",
          fontSize: "22px",
        }}
      >
        Mindbots
      </Box>
    </Box>
  </Link>
);

export default Logo;
