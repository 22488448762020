import { Link } from "react-router-dom";

import { Button } from "@mui/material";

import { useLocation } from "react-router-dom";

interface NavigationLinkProps {
  path: string;
  label: string;
}

const NavigationLink = ({ path, label }: NavigationLinkProps) => {
  const location = useLocation();

  return (
    <Button
      component={Link}
      to={path}
      sx={{
        color: location.pathname === path ? "#0A84FF" : "#86868b",
        fontWeight: 500,
        "&:hover": {
          color: "#0A84FF",
          backgroundColor: "transparent",
        },
        fontSize: "14px",
      }}
    >
      {label}
    </Button>
  );
};

export default NavigationLink;
