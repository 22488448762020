import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface Goal {
  title: string;
  progress: number;
  category: string;
  trend: number;
  color: string;
}

export type GoalsWidgetData = {
  goals: Goal[];
};

export type GoalsWidgetProps = BaseWidgetProps & {
  data: GoalsWidgetData;
};

const GoalsWidget: React.FC<GoalsWidgetProps> = ({
  data: { goals },
  interactionsComponent,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <TrackChangesIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Goals & Progress
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Grid container spacing={2}>
        {goals.map((goal, index) => (
          <Grid item xs={12} key={index}>
            <Box
              sx={{
                backgroundColor: "#fafafa",
                borderRadius: "16px",
                padding: 2,
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Box sx={{ position: "relative", minWidth: 52 }}>
                <CircularProgress
                  variant="determinate"
                  value={goal.progress}
                  size={52}
                  sx={{
                    color: goal.color,
                    "& .MuiCircularProgress-circle": {
                      strokeLinecap: "round",
                    },
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    fontSize: "12px",
                    fontWeight: 500,
                    color: "#1d1d1f",
                  }}
                >
                  {goal.progress}%
                </Typography>
              </Box>

              <Box sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 0.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#1d1d1f",
                    }}
                  >
                    {goal.title}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <TrendingUpIcon sx={{ color: "#32D74B", fontSize: 16 }} />
                    <Typography
                      sx={{
                        color: "#32D74B",
                        fontSize: "12px",
                        fontWeight: 500,
                      }}
                    >
                      {goal.trend}%
                    </Typography>
                  </Box>
                </Box>
                <Chip
                  label={goal.category}
                  size="small"
                  sx={{
                    backgroundColor: "#ffffff",
                    color: "#86868b",
                    fontSize: "11px",
                    height: 20,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GoalsWidget;
