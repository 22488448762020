import { useMutation, useQueryClient } from "@tanstack/react-query";
import DashboardForm from "../DashboardForm";
import { Dashboard, updateDashboard } from "../../services/dasboard";
import { DashboardFields } from "../../validations/dashboard";
import { enqueueSnackbar } from "notistack";

type EditDashboardFormProps = {
  dashboard: Pick<Dashboard, "id" | "name">;
  onClose: () => void;
};

const EditDashboardForm = ({ dashboard, onClose }: EditDashboardFormProps) => {
  const queryClient = useQueryClient();
  const { id, name } = dashboard;

  const { mutateAsync } = useMutation({
    mutationFn: updateDashboard,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["dashboards", "list"] });

      enqueueSnackbar("Space updated successfully", {
        variant: "success",
      });

      onClose();
    },
    onError: ({ message }) => {
      enqueueSnackbar(message, { variant: "error" });
    },
  });

  const handleSubmit = async (data: DashboardFields) => {
    if (data.name === dashboard.name) {
      enqueueSnackbar("No changes made", { variant: "info" });
      return;
    }

    await mutateAsync({ ...data, id });
  };

  return <DashboardForm onSubmit={handleSubmit} initialValues={{ name }} />;
};

export default EditDashboardForm;
