import { Box, Paper, Skeleton, Stack } from "@mui/material";

const WidgetSkeleton = () => (
  <Paper
    elevation={0}
    sx={{
      p: 2,
      borderRadius: 4,
      backgroundColor: "background.paper",
    }}
  >
    <Stack spacing={2}>
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Skeleton variant="text" width={120} height={32} />
        <Skeleton variant="circular" width={32} height={32} />
      </Box>

      <Skeleton variant="rectangular" height={180} sx={{ borderRadius: 2 }} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Skeleton variant="text" width={80} />
        <Skeleton variant="text" width={60} />
      </Box>
    </Stack>
  </Paper>
);

export default WidgetSkeleton;
