import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { BaseWidgetProps } from "../../types/WidgetProps";
import { format, parseISO } from "date-fns";

type CalendarEvent = {
  title: string;
  time: string;
  color: string;
};

export type CalendarWidgetData = {
  date: string;
  events: CalendarEvent[];
};

type CalendarWidgetProps = BaseWidgetProps & {
  data: CalendarWidgetData;
};

const CalendarWidget: React.FC<CalendarWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { events, date } = data;

  const parsedDate = parseISO(date);
  const formattedDate = format(parsedDate, "MMM d");
  const dayOfWeek = format(parsedDate, "EEEE");

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <EventNoteIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Calendar
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {/* Linke Seite - Datum */}
        <Box
          sx={{
            backgroundColor: "#fafafa",
            borderRadius: "16px",
            padding: 2,
            width: "45%",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              letterSpacing: "-0.5px",
              marginBottom: 1,
              color: "#1d1d1f",
            }}
          >
            {formattedDate}
          </Typography>
          <Typography
            sx={{
              color: "#86868b",
              fontSize: "15px",
              fontWeight: 400,
              marginBottom: 0.5,
            }}
          >
            {dayOfWeek}
          </Typography>
          <Typography
            sx={{
              color: "#86868b",
              fontSize: "13px",
            }}
          >
            4 events
          </Typography>
        </Box>

        {/* Rechte Seite - Events */}
        <Box sx={{ width: "55%" }}>
          <Typography
            sx={{
              color: "#86868b",
              fontSize: "13px",
              fontWeight: 500,
              letterSpacing: "0.5px",
              marginBottom: 1.5,
              textTransform: "uppercase",
            }}
          >
            Upcoming
          </Typography>

          <Grid container spacing={1.5}>
            {events.map((event) => (
              <Grid item xs={12} key={event.title}>
                <Box
                  sx={{
                    borderLeft: `2px solid ${event.color}`,
                    paddingLeft: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      marginBottom: "2px",
                      color: "#1d1d1f",
                    }}
                  >
                    {event.title}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    <AccessTimeIcon sx={{ color: "#86868b", fontSize: 16 }} />
                    <Typography
                      sx={{
                        color: "#86868b",
                        fontSize: "13px",
                      }}
                    >
                      {event.time}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default CalendarWidget;
