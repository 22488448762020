import { Alert, AlertTitle, Box, Pagination, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { getDashboards } from "../../services/dasboard";
import DashboardCard from "./DashboardCard";
import DashboardCardSkeleton from "./DashboardSkeleton";
import { useState } from "react";

const SpacesList = () => {
  const [page, setPage] = useState(1);

  const { data, isLoading, error } = useQuery({
    queryKey: ["dashboards", "list", page],
    queryFn: () => getDashboards({ page }),
  });

  if (isLoading) {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
        {Array.from({ length: 3 }).map((_, index) => (
          <DashboardCardSkeleton key={index} />
        ))}
      </Box>
    );
  }

  if (error) {
    return (
      <Alert severity="error" sx={{ "& .MuiAlert-icon": { mt: 0.4 } }}>
        <AlertTitle>
          <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>
            Error
          </Typography>
        </AlertTitle>
        <Typography variant="body1" component="div">
          {error.message ?? "Failed to get spaces"}
        </Typography>
      </Alert>
    );
  }

  if (!data || !data.items?.length) {
    return (
      <Typography sx={{ textAlign: "center", mt: 4, fontSize: "1.25rem" }}>
        No spaces found
      </Typography>
    );
  }

  const { items: dashboards, pagination } = data;

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        {dashboards.map((dashboard) => (
          <Box key={dashboard.id}>
            <Link
              to={`/spaces/${dashboard.id}`}
              style={{ textDecoration: "none" }}
            >
              <DashboardCard {...dashboard} />
            </Link>
          </Box>
        ))}
      </Box>
      {pagination.has_next ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Pagination
            count={pagination.total_pages}
            size="large"
            onChange={(_, value) => setPage(value)}
          />
        </Box>
      ) : null}
    </>
  );
};

export default SpacesList;
