import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteDashboard } from "../../services/dasboard";
import { enqueueSnackbar } from "notistack";

const DeleteButtonStyled = styled(Button)({
  backgroundColor: "#dc3545",
  color: "white",
  fontSize: "0.9rem",
  padding: "6px 16px",
  "&:hover": {
    backgroundColor: "#c82333",
  },
});

type DeleteDashboardButtonProps = {
  id: string;
  onClose: () => void;
};

const DeleteDashboardButton = ({ id, onClose }: DeleteDashboardButtonProps) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: deleteDashboard,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["dashboards", "list"] });
      enqueueSnackbar(data?.message ?? "Space deleted successfully", {
        variant: "success",
      });
      onClose();
    },
    onError: ({ message }) => {
      enqueueSnackbar(message, { variant: "error" });
    },
  });

  return (
    <DeleteButtonStyled
      variant="contained"
      autoFocus
      onClick={() => mutate(id)}
      disabled={isPending}
    >
      Delete
    </DeleteButtonStyled>
  );
};

export default DeleteDashboardButton;
