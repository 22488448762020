import { authInstance } from "../config/authInstance";
import { RegisterFields, VerifyEmailFields } from "../validations/auth";
import { LoginFields } from "../validations/auth";
import { handleApiError } from "../utils/handleApiError";

type RegisterSuccessResponse = {
  message: string;
  user_sub: string;
};

export const register = async (fields: Omit<RegisterFields, "acceptTerms">) => {
  try {
    const response = await authInstance.post<RegisterSuccessResponse>(
      "/auth/register",
      {
        ...fields,
        given_name: fields.givenName,
        family_name: fields.familyName,
        username: fields.email,
      }
    );

    return {
      success: true,
      message: response.data.message,
    };
  } catch (error) {
    return handleApiError(error, {
      400: "Invalid registration data or user already exists",
    });
  }
};

export const verifyEmail = async (
  fields: VerifyEmailFields & { username: string }
) => {
  try {
    await authInstance.post("/auth/verify-email", fields);

    return {
      success: true,
      message: "Email verified successfully",
    };
  } catch (error) {
    return handleApiError(error, {
      400: "Invalid or expired verification code",
    });
  }
};

export const resendVerificationCode = async (data: { username: string }) => {
  try {
    await authInstance.post("/auth/resend-verification", data);

    return {
      success: true,
      message: "Verification code resent successfully",
    };
  } catch (error) {
    return handleApiError(error);
  }
};

type LoginSuccessResponse = {
  access_token: string;
};

type LoginSuccessResult = {
  success: true;
  message: string;
  data: {
    accessToken: string;
  };
};

type LoginErrorResult = {
  success: false;
  redirect?: string;
  message: string;
};

export const login = async ({
  email,
  password,
}: LoginFields): Promise<LoginSuccessResult | LoginErrorResult> => {
  try {
    const response = await authInstance.post<LoginSuccessResponse>(
      "/auth/login",
      {
        password,
        username: email,
      },
      {
        skipInterceptor: true,
      }
    );

    return {
      success: true,
      message: "Successfully logged in",
      data: {
        accessToken: response.data.access_token,
      },
    };
  } catch (error) {
    return handleApiError(error, {
      400: "Email not verified. Please check your email for verification code.",
      401: "Invalid credentials",
    });
  }
};

type LogoutSuccessResponse = {
  message: string;
};

export const logout = async () => {
  try {
    const response = await authInstance.post<LogoutSuccessResponse>(
      "/auth/logout"
    );

    return {
      success: true,
      message: response.data.message,
    };
  } catch (error) {
    return handleApiError(error);
  }
};

type UserData = {
  user_id: string;
  email: string;
  username: string;
};

type CheckAuthSuccessResponse = {
  is_authenticated: boolean;
  user: UserData;
};

export const checkAuth = async () => {
  try {
    const response = await authInstance.get<CheckAuthSuccessResponse>(
      "/auth/check-auth"
    );

    return response.data;
  } catch (error) {
    console.log(error);

    return {
      is_authenticated: false,
      user: null,
    };
  }
};
