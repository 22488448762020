import { Box, Card, Skeleton } from "@mui/material";

const DashboardCardSkeleton = () => {
  return (
    <Card
      sx={{
        background: "linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)",
        borderRadius: "16px",
        p: 4,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: 2.5,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Skeleton variant="text"  width="60%" height={40} sx={{ mb: 2 }} />

            <Box
              sx={{
                display: "flex",
                alignItems: {
                  xs: "flex-start",
                  sm: "center",
                },
                gap: 1,
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
              }}
            >
              <Skeleton
                variant="rounded"
                width={100}
                height={28}
                sx={{ borderRadius: "12px" }}
              />
              <Skeleton variant="rounded" width={120} height={28} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
          <Skeleton variant="circular" width={32} height={32} />
          <Skeleton variant="circular" width={32} height={32} />
        </Box>
      </Box>
    </Card>
  );
};

export default DashboardCardSkeleton;
