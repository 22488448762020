import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./useAuth";

const AuthenticationLayout = () => {
  const { data } = useAuth();

  if (data?.is_authenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Box sx={{ py: 8, px: 2, maxWidth: 550 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default AuthenticationLayout;
