import StreamProvider from "./StreamProvider";
import Stream from "./Stream";

const StreamPage = () => {
  return (
    <StreamProvider>
      <Stream />
    </StreamProvider>
  );
};

export default StreamPage;
