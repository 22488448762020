import React, { useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import RefreshIcon from "@mui/icons-material/Refresh";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { BaseWidgetProps } from "../../types/WidgetProps";

interface Quote {
  text: string;
  author: string;
  category: string;
  color: string;
}

export type QuoteWidgetData = {
  quotes: Quote[];
};

export type QuoteWidgetProps = BaseWidgetProps & {
  data: QuoteWidgetData;
};

const QuoteWidget: React.FC<QuoteWidgetProps> = ({
  data: { quotes },
  interactionsComponent,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleRefresh = () => {
    setCurrentIndex((prev) => (prev + 1) % quotes.length);
  };

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FormatQuoteIcon sx={{ color: "#86868b" }} />
            <Typography
              sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
            >
              Quote of the Day
            </Typography>
          </Box>
        </Box>
        {interactionsComponent}
      </Box>

      <Box
        sx={{
          backgroundColor: `${quotes[currentIndex].color}08`,
          borderRadius: "16px",
          padding: 2,
          marginBottom: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            color: "#1d1d1f",
            marginBottom: 2,
            lineHeight: 1.4,
          }}
        >
          "{quotes[currentIndex].text}"
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "#86868b", fontSize: "13px" }}>
            — {quotes[currentIndex].author}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <FavoriteIcon
              sx={{ color: quotes[currentIndex].color, fontSize: 16 }}
            />
            <Typography
              sx={{
                color: quotes[currentIndex].color,
                fontSize: "12px",
                fontWeight: 500,
              }}
            >
              {quotes[currentIndex].category}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton onClick={handleRefresh} size="small" data-no-dnd="true">
          <RefreshIcon sx={{ fontSize: 20, color: "#86868b" }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default QuoteWidget;
