import { Box, Skeleton, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getDashboards } from "../../../services/dasboard";
import SpaceItem from "./SpaceItem";

type SpacesListProps = {
  widgetId: string;
};

const SpacesList = ({ widgetId }: SpacesListProps) => {
  const {
    data,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["dashboards", "list"],
    queryFn: () => getDashboards({}),
  });

  if (isLoading) {
    return (
      <Box>
        {[1, 2, 3].map((item) => (
          <Box
            key={item}
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "4px 16px",
              gap: 2.5,
            }}
          >
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="text" width={120} height={24} />
          </Box>
        ))}
      </Box>
    );
  }

  if (error) {
    return (
      <Typography textAlign="center" color="error">
        {error.message ?? "Failed to get spaces"}
      </Typography>
    );
  }

  if (!data || !data.items?.length) {
    return (
      <Typography textAlign="center" color="GrayText">
        No spaces found
      </Typography>
    );
  }

  return (
    <>
      {data.items.map(({ id, name, widgets }) => (
        <SpaceItem
          key={id}
          dashboard={{ id, name, widgets }}
          widgetId={widgetId}
        />
      ))}
    </>
  );
};

export default SpacesList;
