import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { initializeInterceptors } from "../config/authInstance";

const Interceptors = () => {
  const navigate = useNavigate();

  useEffect(() => {
    initializeInterceptors(navigate);
  }, [navigate]);

  return null;
};

export default Interceptors;
