import { formatDistanceToNow, format, isYesterday, isToday } from "date-fns";

export const formatDate = (date: string) => {
  const dateObj = new Date(date);
  let formattedDate = "";

  if (isToday(dateObj)) {
    formattedDate = `Today, ${format(date, "HH:mm")}`;
  } else if (isYesterday(date)) {
    formattedDate = "Yesterday";
  } else {
    formattedDate = formatDistanceToNow(date, { addSuffix: true });
  }

  return formattedDate;
};
