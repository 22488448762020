import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import { BaseWidgetProps } from "../../types/WidgetProps";

type Balance = {
  amount: string;
  change: string;
};

interface Transaction {
  category: string;
  amount: string;
  type: "income" | "expense";
  date: string;
}

interface Budget {
  category: string;
  spent: number;
  total: number;
  color: string;
}

export type FinanceWidgetData = {
  balance: Balance;
  transactions: Transaction[];
  budgets: Budget[];
};

type FinanceWidgetProps = BaseWidgetProps & {
  data: FinanceWidgetData;
};

const FinanceWidget: React.FC<FinanceWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { budgets, transactions, balance } = data;

  const balanceAmount = parseFloat(balance.amount);
  const balanceChange = parseFloat(balance.change);

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        p: 2,
        pb: 3,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AccountBalanceWalletIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Finance
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      {/* Balance Section */}
      <Box sx={{ marginBottom: 3 }}>
        <Typography
          sx={{ color: "#86868b", fontSize: "13px", marginBottom: 0.5 }}
        >
          Current Balance
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            fontSize: "32px",
            color: "#1d1d1f",
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          ${balanceAmount.toFixed(2)}
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              color: balanceChange > 0 ? "#32D74B" : "#FF3B30",
              backgroundColor: balanceChange > 0 ? "#32D74B15" : "#FF3B3015",
              padding: "4px 8px",
              borderRadius: "8px",
              fontWeight: 500,
            }}
          >
            {balanceChange > 0 ? "+" : ""}
            {balanceChange.toFixed(1)}%
          </Typography>
        </Typography>
      </Box>

      {/* Recent Transactions */}
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 500,
          color: "#1d1d1f",
          marginBottom: 1.5,
        }}
      >
        Recent Transactions
      </Typography>
      <Box sx={{ marginBottom: 3 }}>
        {transactions.map((transaction, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 1.5,
              backgroundColor: "#f5f5f7",
              borderRadius: "12px",
              marginBottom: index !== transactions.length - 1 ? 1 : 0,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <LocalAtmIcon
                sx={{
                  color: transaction.type === "income" ? "#32D74B" : "#FF3B30",
                  fontSize: 20,
                }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 500, color: "#1d1d1f" }}
                >
                  {transaction.category}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#86868b" }}>
                  {transaction.date}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
              {transaction.type === "income" ? (
                <TrendingUpIcon sx={{ color: "#32D74B", fontSize: 16 }} />
              ) : (
                <TrendingDownIcon sx={{ color: "#FF3B30", fontSize: 16 }} />
              )}
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 500,
                  color: transaction.type === "income" ? "#32D74B" : "#FF3B30",
                }}
              >
                ${parseFloat(transaction.amount).toFixed(2)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>

      {/* Budget Progress */}
      <Typography
        sx={{
          fontSize: "15px",
          fontWeight: 500,
          color: "#1d1d1f",
          marginBottom: 1.5,
        }}
      >
        Budget Overview
      </Typography>
      {budgets.map((budget, index) => (
        <Box
          key={index}
          sx={{ marginBottom: index !== budgets.length - 1 ? 2 : 0 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 0.5,
            }}
          >
            <Typography sx={{ fontSize: "13px", color: "#86868b" }}>
              {budget.category}
            </Typography>
            <Typography sx={{ fontSize: "13px", color: "#86868b" }}>
              ${budget.spent} / ${budget.total}
            </Typography>
          </Box>
          <LinearProgress
            variant="determinate"
            value={(budget.spent / budget.total) * 100}
            sx={{
              backgroundColor: "#f5f5f7",
              borderRadius: 2,
              height: 6,
              "& .MuiLinearProgress-bar": {
                backgroundColor: budget.color,
                borderRadius: 2,
              },
            }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default FinanceWidget;
