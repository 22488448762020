import ChatIcon from "@mui/icons-material/Chat";
import { IconButton, Tooltip } from "@mui/material";

type ChatWithWidgetProps = {
  onClick: () => void;
};

const ChatWithWidget = ({ onClick }: ChatWithWidgetProps) => {
  return (
    <Tooltip title="Chat with widget">
      <IconButton
        data-no-dnd="true"
        size="small"
        onClick={onClick}
        sx={{
          color: "#86868b",
          "&:hover": { color: "#0A84FF" },
        }}
      >
        <ChatIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};

export default ChatWithWidget;
