import { TextField } from "@mui/material";

const MessageTextField = ({
  inputRef,
  value,
  setValue,
  onSend,
  placeholder,
}: {
  inputRef: React.Ref<HTMLInputElement>;
  value: string;
  setValue: (value: string) => void;
  onSend: () => void;
  placeholder: string;
}) => {
  return (
    <TextField
      inputRef={inputRef}
      fullWidth
      multiline
      maxRows={4}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder={placeholder}
      variant="outlined"
      size="small"
      onKeyDown={(e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          onSend();
        }
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          backgroundColor: "transparent",
          "& fieldset": { border: "none" },
        },
        "& .MuiInputBase-input": {
          fontSize: { xs: "14px", sm: "16px" },
          padding: { xs: "8px 4px", sm: "10px 4px" },
          lineHeight: 1.5,
          overflow: "auto",
          "&::-webkit-scrollbar": { display: "none" },
        },
      }}
    />
  );
};

export default MessageTextField;
