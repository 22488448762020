import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Box, Chip, List, ListItem, Typography } from "@mui/material";
import React from "react";
import { IconType } from "../../const/iconsRegistry";
import { BaseWidgetProps } from "../../types/WidgetProps";
import { formatDate } from "../../utils/formatDate";
import { getIcon } from "../../utils/getIcon";

interface CompletedTask {
  title: string;
  category: string;
  date: string;
  impact: "high" | "medium" | "low";
  color: string;
  icon: IconType;
}

export type TaskCompletionWidgetData = {
  tasks: CompletedTask[];
};

type TaskCompletionWidgetProps = BaseWidgetProps & {
  data: TaskCompletionWidgetData;
};

const TaskCompletionWidget: React.FC<TaskCompletionWidgetProps> = ({
  interactionsComponent,
  data,
}) => {
  const { tasks } = data;

  return (
    <Box
      sx={{
        backgroundColor: "#ffffff",
        borderRadius: "20px",
        padding: 2,
        color: "#000",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 1,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          <AutoAwesomeRoundedIcon sx={{ color: "#86868b" }} />
          <Typography
            sx={{ color: "#86868b", fontSize: "13px", fontWeight: 500 }}
          >
            Activity
          </Typography>
        </Box>
        {interactionsComponent}
      </Box>

      <Typography
        variant="h3"
        sx={{
          fontWeight: 600,
          fontSize: "24px",
          color: "#1d1d1f",
          marginBottom: 2,
        }}
      >
        Completed this week
      </Typography>

      <List sx={{ padding: 0 }}>
        {tasks.map((task, index) => {
          const icon = getIcon(task.icon, { sx: { color: task.color } });
          const formattedDate = formatDate(task.date);

          return (
            <ListItem
              key={index}
              sx={{
                padding: 1.5,
                marginBottom: 1.5,
                backgroundColor: "#f5f5f7",
                borderRadius: "12px",
                display: "block",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1.5 }}>
                <Box
                  sx={{
                    backgroundColor: "#ffffff",
                    borderRadius: "8px",
                    padding: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {icon}
                </Box>
                <Box sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 500,
                      color: "#1d1d1f",
                      marginBottom: 0.5,
                    }}
                  >
                    {task.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Chip
                      label={task.category}
                      size="small"
                      sx={{
                        backgroundColor: "#ffffff",
                        color: "#86868b",
                        fontSize: "11px",
                        height: 20,
                      }}
                    />
                    <Typography sx={{ color: "#86868b", fontSize: "12px" }}>
                      {formattedDate}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TaskCompletionWidget;
