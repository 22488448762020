import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Interceptors from "./components/Interceptors";
import { Navigate } from "react-router-dom";
import DashboardPage from "./pages/Dashboard";
import LoginPage from "./pages/Login";
import RegisterPage from "./pages/Register";
import VerifyEmail from "./pages/VerifyEmail";
import StreamPage from "./pages/Stream";
import SpacesPage from "./pages/Spaces";
import Layout from "./components/Layout";
import AuthenticationLayout from "./components/Layout/AuthenticationLayout";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<SpacesPage />} />
            <Route path="/stream" element={<StreamPage />} />
            <Route path="/spaces/:id" element={<DashboardPage />} />
          </Route>
          <Route path="/auth" element={<AuthenticationLayout />}>
            <Route path="register" element={<RegisterPage />} />
            <Route path="login" element={<LoginPage />} />
            <Route path="verify-email" element={<VerifyEmail />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <SnackbarProvider
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          maxSnack={3}
        />
        <Interceptors />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
