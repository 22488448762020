import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Children, cloneElement } from "react";

interface SortableItemProps {
  id: string;
  dashboardId: string;
  children: React.ReactNode;
}

function SortableItem({ id, dashboardId, children }: SortableItemProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transformOrigin: "50% 50%",
    cursor: isDragging ? "grabbing" : "grab",
    opacity: isDragging ? 0.5 : 1,
    transform: CSS.Transform.toString(
      transform ? { ...transform, scaleX: 1, scaleY: 1 } : null
    ),
    transition: transition || "opacity 0.05s linear",
  };

  return cloneElement(Children.only(children) as React.ReactElement, {
    ref: setNodeRef,
    style: style,
    ...attributes,
    ...listeners,
  });
}

export default SortableItem;
