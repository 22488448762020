import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const EmptyStateContainer = styled(Box)({
  flex: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "20px",
});

const EmptyState = () => (
  <EmptyStateContainer>
    <Box>
      <Typography variant="h4" fontWeight={500} sx={{ mb: 2.5 }}>
        No messages yet
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Start a conversation by typing a message or using the microphone
      </Typography>
    </Box>
  </EmptyStateContainer>
);

export default EmptyState; 