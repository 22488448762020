import { SvgIconProps } from "@mui/material";
import { IconType } from "../const/iconsRegistry";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import TimerIcon from "@mui/icons-material/Timer";
import EmailIcon from "@mui/icons-material/Email";
import EventNoteIcon from "@mui/icons-material/EventNote";
import FlightIcon from "@mui/icons-material/Flight";
import ScheduleIcon from "@mui/icons-material/Schedule";

export const getIcon = (type: IconType, props?: SvgIconProps) => {
  switch (type) {
    case "FLIGHT":
      return <FlightIcon {...props} />;
    case "EMAIL":
      return <EmailIcon {...props} />;
    case "EVENT":
      return <EventNoteIcon {...props} />;
    case "SCHEDULE":
      return <ScheduleIcon {...props} />;
    case "SELF_IMPROVEMENT":
      return <SelfImprovementIcon {...props} />;
    case "TIMER":
      return <TimerIcon {...props} />;
    case "LOCAL_CAFE":
      return <LocalCafeIcon {...props} />;
    case "FITNESS_CENTER":
      return <FitnessCenterIcon {...props} />;
    default:
      return null;
  }
};
