import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { DashboardFields, dashboardSchema } from "../validations/dashboard";

interface DashboardFormProps {
  onSubmit: (data: DashboardFields) => Promise<void>;
  initialValues?: DashboardFields;
}

const DashboardForm = ({ onSubmit, initialValues }: DashboardFormProps) => {
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm<DashboardFields>({
    resolver: zodResolver(dashboardSchema),
    defaultValues: {
      name: initialValues?.name || "",
    },
  });

  const submitHandler = handleSubmit(async (data) => {
    console.log("Form submitted with data:", data);
    await onSubmit(data);
  });

  return (
    <form onSubmit={submitHandler} noValidate>
      <TextField
        {...register("name")}
        label="Name"
        type="text"
        required
        fullWidth
        error={!!errors.name}
        helperText={errors.name?.message}
        sx={{ mb: 2, borderRadius: "0.5rem" }}
      />
      <Button
        type="submit"
        variant="contained"
        onClick={submitHandler}
        fullWidth
        disabled={isSubmitting}
        sx={{
          py: 1.5,
          borderRadius: "0.5rem",
        }}
      >
        {isSubmitting ? "Submitting..." : "Submit"}
      </Button>
    </form>
  );
};

export default DashboardForm;
