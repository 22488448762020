import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import ResendCodeForm from "../components/ResendCodeForm";
import VerifyEmailForm from "../components/VerifyEmailForm";
import { Box } from "@mui/material";

const VerifyEmail = () => {
  const [username] = useLocalStorage("username", "");
  const navigate = useNavigate();

  useEffect(() => {
    if (!username) {
      navigate("/register");
    }
  }, [username, navigate]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <VerifyEmailForm />
      <ResendCodeForm />
    </Box>
  );
};

export default VerifyEmail;