import { Box } from "@mui/material";
import { HTMLAttributes, forwardRef } from "react";

export type GridItemSize =
  | "1x1"
  | "2x2"
  | "3x3"
  | "2x1"
  | "1x2"
  | "3x1"
  | "1x3"
  | "2x3"
  | "3x2";

type GridItemProps = {
  size?: GridItemSize;
  children: React.ReactNode;
} & HTMLAttributes<HTMLDivElement>;

const GridItem = forwardRef<HTMLDivElement, GridItemProps>(
  (
    { size = "1x1", children, style, className, ...props },
    ref
  ) => {
    const [colSpan, rowSpan] = size.split("x").map(Number);

    const finalStyle = {
      gridColumn: `span ${colSpan}`,
      gridRow: `span ${rowSpan}`,
      ...style,
    };

    const shouldGrow = rowSpan > 1;

    return (
      <Box
        ref={ref}
        style={finalStyle}
        className={`grid-item ${className || ""}`}
        sx={{
          display: "flex",
          flexDirection: "column",
          "& > div": {
            height: shouldGrow ? "100%" : "auto",
          },
        }}
        {...props}
      >
        {children}
      </Box>
    );
  }
);

GridItem.displayName = "GridItem";

export default GridItem;
