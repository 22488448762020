import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import DeleteDashboardButton from "./DeleteDashboardButton";

const DialogTitleStyled = styled(DialogTitle)({
  fontSize: "1.25rem",
  fontWeight: 700,
  lineHeight: 1.3,
  padding: 0,
  marginBottom: 10,
  color: "#1a1a1a",
});

const DialogContentStyled = styled(DialogContent)({
  padding: 0,
  marginBottom: 25,
  "& .MuiDialogContentText-root": {
    fontSize: "1rem",
    lineHeight: 1.4,
    color: "rgba(0, 0, 0, 0.75)",
    padding: "0px !important",
  },
});

const DialogActionsStyled = styled(DialogActions)({
  gap: "6px",
  padding: 0,
});

const CancelButtonStyled = styled(Button)({
  fontSize: "0.9rem",
  padding: "6px 16px",
});

interface DeleteDashboardDialogProps {
  id: string;
}

const DeleteDashboardDialog = ({ id }: DeleteDashboardDialogProps) => {
  const [open, setOpen] = useState(false);

  const handleEvent = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleClickOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleEvent(e);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={handleClickOpen} style={{ color: "red" }}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClick={handleEvent}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "12px",
            p: {
              xs: 3,
              sm: 3.5,
            },
            maxWidth: "375px",
            mx: 2,
          },
        }}
      >
        <DialogTitleStyled id="alert-dialog-title">
          Delete Space
        </DialogTitleStyled>
        <DialogContentStyled>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this space? This action is permanent
            and cannot be recovered.
          </DialogContentText>
        </DialogContentStyled>
        <DialogActionsStyled>
          <CancelButtonStyled onClick={handleClose} variant="outlined">
            Cancel
          </CancelButtonStyled>
          <DeleteDashboardButton id={id} onClose={handleClose} />
        </DialogActionsStyled>
      </Dialog>
    </>
  );
};

export default DeleteDashboardDialog;
