import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";
import { register } from "../services/auth";
import { RegisterFields, registerSchema } from "../validations/auth";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [, setUsername] = useLocalStorage("username", "");

  const form = useForm<RegisterFields>({
    resolver: zodResolver(registerSchema),
    defaultValues: {
      password: "",
      email: "",
      givenName: null,
      familyName: null,
      birthdate: "",
      acceptTerms: false,
    },
  });

  const onSubmit = async ({ acceptTerms: _, ...data }: RegisterFields) => {
    const { message, success } = await register(data);

    if (!success) {
      enqueueSnackbar(message, {
        variant: "error",
      });

      return;
    }

    enqueueSnackbar(message, {
      variant: "success",
    });

    setUsername(data.email);
    form.reset();
    navigate("/auth/verify-email");
  };

  const { isSubmitting, errors } = form.formState;

  return (
    <form onSubmit={form.handleSubmit(onSubmit)} noValidate>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" sx={{ color: "text.secondary" }}>
            Account Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...form.register("email")}
            label="Email"
            type="email"
            required
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...form.register("password")}
            label="Password"
            type="password"
            fullWidth
            required
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ color: "text.secondary" }}>
            Personal Information
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...form.register("givenName")}
            label="Given Name"
            fullWidth
            error={!!errors.givenName}
            helperText={errors.givenName?.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...form.register("familyName")}
            label="Family Name"
            fullWidth
            error={!!errors.familyName}
            helperText={errors.familyName?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            {...form.register("birthdate")}
            label="Birth Date"
            type="date"
            fullWidth
            required
            InputLabelProps={{ shrink: true }}
            error={!!errors.birthdate}
            helperText={errors.birthdate?.message}
          />
        </Grid>
        <Grid item xs={12} sx={{ mt: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                {...form.register("acceptTerms", {
                  required: "You must accept the terms of service.",
                })}
                required
                color="primary"
              />
            }
            label="I hereby accept the Terms of Services"
          />
          {errors.acceptTerms && (
            <Typography variant="body2" color="error">
              {errors.acceptTerms.message}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sx={{ mt: 1.3 }}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            disabled={isSubmitting}
            sx={{
              py: 1.5,
              textTransform: "none",
              fontSize: "1.1rem",
              fontWeight: "bold",
            }}
          >
            {isSubmitting ? "Creating Account..." : "Register"}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            textAlign="center"
            sx={{
              color: "text.secondary",
            }}
          >
            Already have an account?{" "}
            <Link
              to="/auth/login"
              style={{
                textDecoration: "none",
                fontWeight: "bold",
              }}
            >
              Sign in
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </form>
  );
};

export default RegisterForm;
