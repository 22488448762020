import { Box } from "@mui/material";
import GridItem from "./GridItem";
import DashboardWidgetInteractions from "./DashboardWidgetInteractions";
import { Widget } from "../../types/Widget";
import { renderWidget } from "../../utils/renderWidget";
import SortableItem from "./SortableItem";
import { GridItemSize } from "./GridItem";

type WidgetsGridProps = {
  widgets: Widget[];
  dashboardId: string;
  page: number;
};

const WidgetsGrid = ({ widgets, dashboardId, page }: WidgetsGridProps) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
        gap: 2,
        overflow: "hidden",
        position: "relative",
      }}
    >
      {widgets.map((widget) => {
        const component = renderWidget(
          widget as Widget,
          <DashboardWidgetInteractions
            page={page}
            dashboardId={dashboardId}
            widgetId={widget.id}
            isLiked={widget.is_liked}
            isDisliked={widget.is_disliked}
          />
        );

        if (!component) return null;

        return (
          <SortableItem
            key={widget.id}
            id={widget.id}
            dashboardId={dashboardId}
          >
            <GridItem size={widget.size as GridItemSize}>{component}</GridItem>
          </SortableItem>
        );
      })}
    </Box>
  );
};

export default WidgetsGrid;
