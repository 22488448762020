import { AxiosError } from "axios";

export type ErrorResponse = {
  success: false;
  message: string;
  redirect?: string;
};

export const handleApiError = (
  error: unknown,
  customErrorMessages: Record<number, string> = {},
  defaultMessage = "Something went wrong"
): ErrorResponse => {
  console.error(error);
  const axiosError = error as AxiosError;

  if (!axiosError.response) {
    return {
      success: false,
      message: defaultMessage,
    };
  }

  const status = axiosError.response.status;
  const defaultErrors: Record<number, string> = {
    400: "Bad request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not found",
    422: "Validation error",
    429: "Too many requests. Please try again later",
    500: "Internal server error. Please try again later",
  };

  return {
    success: false,
    message:
      customErrorMessages[status] || defaultErrors[status] || defaultMessage,
  };
};
